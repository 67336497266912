.header-mobile {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    color: #fff;
    font-family: "Montserrat", Helvetica;
    position: relative; /* Ensure dropdown is positioned correctly */
}

.header-content {
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Align items vertically in the center */
    justify-content: space-between; /* Space between hamburger and logo */
    width: 100%; /* Full width to ensure proper alignment */
    padding: 10px; /* Add padding for better spacing */
    margin-left: 5px;
}

.hamburger {
    margin-right: auto;
    cursor: pointer; /* Change cursor to pointer for the hamburger icon */
    margin-top: 5px;
    padding-right: 10px;
}

.mobileBanner {
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center the logo horizontally */
    align-items: center; /* Center items vertically */
    margin-right: 15%;
    flex-grow: 1; /* Allow the banner to take up available space */
}

.mobile-logo {
    width: auto; /* Allow logo to maintain its aspect ratio */
    max-height: 30px; /* Set a fixed height for the logo */
}

.mobile-dropdown-content {
    display: flex; /* Use flexbox for stacking */
    flex-direction: column; /* Stack items vertically */
    position: relative; /* Position it below the hamburger */
    top: 0; /* Adjust based on your layout */
    background-color: transparent; /* Match the header background */
    width: 100%; /* Full width */
    z-index: 1; /* Ensure it appears above other elements */
    padding: 10px; /* Add some padding */
    border-radius: 10px; /* Rounded corners */
}

.mobile-dropdown-content a {
    padding: 7px 0 7px 0px; /* Add padding for clickable area */
    color: #fff; /* Text color */
    text-decoration: none; /* Remove underline */
    text-align: start; /* Center text */
    width: 100%;
}

.mobile-dropdown-content .dropdown-signin {
    padding: 15px 10px; /* Add padding for clickable area */
    color: #fff; /* Text color */
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none; /* Remove underline */
    text-align: center; /* Center text */
    width: 123px;
}

.mobile-nav-button {
    display: flex;
    align-self: center;
    background-color: #AE00FF; /* Adjust button color */
    color: white;
    border-radius: 15px;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: "Inter", Helvetica;
}
.mobile-dropdown-content a:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Optional: hover effect */
}

/* Show dropdown when open */
.dropdown.open .dropdown-content {
    display: flex; /* Show dropdown content when open */
    transform: scale(1); /* Scale to full size */
    opacity: 1; /* Fade in */
    transition: all 0.3s ease;
}

/* Media query for mobile devices */
@media (max-width: 810px) {
    .header-mobile {
        display: flex;
        flex-direction: column; /* Stack items vertically */
    }
    .header-content {
        justify-content: center; /* Center items on smaller screens */
    }
}

.separator {
    height: 1px; /* Height of the line */
    background-color: rgba(255, 255, 255, 0.5); /* Color of the line */
    margin: 5px 0; /* Space around the line */
    width: 95%;
    align-self: start;
}
