.dashboard-container {
    background-color: #EAD4FF;
    padding: 20px;
    font-family: 'Montserrat', sans-serif;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 95vh;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease;
}

.coming-soon-overlay {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.coming-soon-overlay h1 {
    color: rgb(230, 8, 198);
    font-size: 40px;
    font-weight: 800;
    font-family: 'Montserrat', sans-serif;
}

.coming-soon-overlay h3 {
    color: rgb(230, 8, 198);
    font-size: 20px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

.dashboard-container h2{
    font-size: 18px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    color: #202020;
}

.dashboard-container h3{
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #202020;
    transition: all 0.3s ease;
}


.dashboard-container.resized .dashboard-header .dashboard-actions {
    width: 100%;
}

.dashboard-actions::-webkit-scrollbar{
    display: none;
}

.dashboard-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.dashboard-actions {
    display: flex;
    flex-direction: row;
    gap: 20px;
    overflow-x: scroll;
    justify-content: space-between;
}



.dashboard-container.not-resized .dashboard-header .dashboard-actions button {
    margin-left: 10px;
    width: 400px;
    max-width: 400px;
    padding: 10px 15px;
    background-color: rgba(255, 255, 255, 0.75);
    color: white;
    border: none;
    border-radius: 13px;
    cursor: pointer;
    height: 88px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 10px;
    transition: all 0.3s ease
}

.dashboard-container.resized .dashboard-header .dashboard-actions button{
    width: 300px;
    min-width: 240px;
    background-color: rgba(255, 255, 255, 0.75);
    margin-left: 10px;
    padding: 10px 15px;
    color: white;
    border: none;
    border-radius: 13px;
    cursor: pointer;
    height: 88px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 10px;
    transition: all 0.3s ease
}

.dashboard-actions .action-label {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
    transition: all 0.3s ease    
}

.dashboard-actions .action-label p{
    margin-bottom: 0px;
}

.dashboard-container.not-resized .dashboard-actions h2 {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 0px;
    font-weight: 500;
    color: #202020;
    transition: all 0.3s ease
}

.dashboard-container.not-resized .dashboard-actions p {
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
    margin-top: 0px;
    color: #202020;
    transition: all 0.3s ease
}

.dashboard-container.resized .dashboard-actions h2 {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 0px;
    font-weight: 500;
    color: #202020;
    transition: all 0.3s ease
}

.dashboard-container.resized .dashboard-actions p {
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
    margin-top: 0px;
    color: #202020;
    transition: all 0.3s ease
}

.current-campaigns{
    width: 47%;
    height: 291px;
    margin-left: 10px;
    background-color: rgba(255, 255, 255, 0.75);
}


.current-campaigns, .new-offers {
    margin-top: 20px;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 13px;
}

.current-campaigns-header{
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 10px;
}

.current-campaigns-header h2{
    color: black;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

.current-campaigns-header h3{
    color: rgb(32, 32, 32, .7);
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}

.campaign-list, .offer-item {
    margin-top: 10px;
}

.campaign-item, .offer-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    border-bottom: 1px solid #E0E0E0;
}

.footer-info, .footer-stats {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 10px;
    background-color: #F5F5F5;
    border-radius: 5px;
}

.dashboard-container .blur{
    filter: none
}

.campaign-item .CampaignGame{
    color: #202020;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.campaign-item .CampaignTitle{
    color: #202020;
    font-size: 18px !important; 
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.campaign-item .CampaignValue{
    color: #202020;
    font-size: 18px; 
    font-weight: 500 ;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.campaign-item .game-icon{
    width: 40px;
    height: 40px;
    border-radius: 10px;
}

.offer-item .offer-campaign-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.content-frequency{
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: start;
    margin-left: 10px
}

.content-frequency h2{
    font-size: 18px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    color: #000000;
    line-height: 1;
}

.content-frequency h3{
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    color: rgb(0, 0, 0, .5);
    margin-left: 10px;
    line-height: 1;
}

.content-frequency.frequency-text{
    margin-top: 20px !important;
    margin-bottom: 5px !important;
    margin-left: 25px;
}


.content-frequency .current-campaigns{
    margin-left: 0px !important;
}

.frequency-text h3:hover{
    color: rgb(0, 0, 0);
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 4px;
}


.quick-numbers{
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    gap: 20px;
    height: 40%;
    width: 100%
}



.numbers-pie-chart{
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    height: 58%;
    width: 100%
}


.quick-numbers-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;
    margin: 0px;
    text-align: center;

}


.content-frequency .quick-numbers-item h2, .numbers-pie-chart-item h2{
    margin: 0px;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    color: #202020;
}



.content-frequency .current-campaigns{
    margin-top: 0px;
}





.content-frequency .quick-numbers-item h3, .numbers-pie-chart-item h3{
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    color: rgb(32, 32, 32, .7);
}

.quick-numbers-item h3{
    margin-top: 5px !important;
}






.numbers-pie-chart-item{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 100%;
    margin: 0px;
    text-align: center;


}

.quick-numbers-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: none;
    margin-left: 0px;
    width: 49%;
    padding: 0px
}



.current-offers{
    padding-top: 20px !important;
}




@media (max-width: 850px) {

    .dashboard-header {
        overflow-x: scroll;
    }
}


.creator-dashboard .header-app-route {
    position: relative;
    z-index: 2;
}

@media (min-width: 1700px) {

    .dashboard-container.not-resized .dashboard-header .dashboard-actions {
        gap: 65px;
    }

    .dashboard-container.resized .dashboard-header .dashboard-actions {
        gap: 45px;
    }

    .dashboard-container.not-resized .dashboard-header .dashboard-actions button {
        margin-left: 10px;
        width: 450px;
        max-width: 450px;
        padding: 10px 15px;
        background-color: rgba(255, 255, 255, 0.75);
        color: white;
        border: none;
        border-radius: 13px;
        cursor: pointer;
        height: 88px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        transition: all 0.3s ease
    }
    
    .dashboard-container.resized .dashboard-header .dashboard-actions button{
        width: 400px;
        min-width: 240px;
        max-width: 400px;
        background-color: rgba(255, 255, 255, 0.75);
        margin-left: 10px;
        padding: 10px 15px;
        color: white;
        border: none;
        border-radius: 13px;

        cursor: pointer;
        height: 88px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        gap: 10px;
        transition: all 0.3s ease
    }
    
    
}