.creator-page {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Set maximum height to viewport height */
  overflow-y: scroll; /* Allow vertical scrolling */
  background-color: #EAD4FF;
  overflow-x: hidden;
  width: 100vw;
}

.public-profile {
  min-height: 100vh; /* Ensure it takes at least the full height of the viewport */
  display: flex; /* Use flexbox to allow for proper layout */
  flex-direction: column; /* Stack children vertically */
  overflow-y: hidden; /* Allow vertical scrolling */
}

.creator-page .header {
    display: none;
}
.creator-page .footer {
  display: none;
}

.public-profile .footer {
  display: flex;
}

.public-profile .footer p{
color: #5E00D4;
}

.creator-page .profile-banner {
  position: relative; /* Make the banner sticky */
  top: 0; /* Stick to the top of the viewport */
  width: 100%;
  height: 250px; /* Original height */
  min-height: 250px;
  z-index: 10; /* Ensure it stays above other content */
  transition: height 0.3s ease; /* Smooth transition for height */

}

.public-profile .profile-banner {
min-height: 150px;
}



.profile-banner.collapsed {
  height: 150px !important; /* Height when collapsed */
  max-height: 150px !important;
}

.profile-banner.collapsed .profile-displayname{
  display: none;
}

.profile-banner.collapsed .profile-location{
  display: none;
}

.profile-banner.collapsed .question-container.bio{
  display: none;
}

.profile-banner img {
z-index: 0; 
}

.profile-banner .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(94, 0, 212, 0.2); /* Purple color with 50% opacity */
  z-index: 1; /* Ensure the overlay is above the banner image */
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: white;
}

.profile-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  z-index: 2;
  transition: opacity 0.3s ease; /* Smooth transition for opacity */
}

.profile-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid white;
}

.edit-button {
  position: absolute;
  top: 20%;
  left: 10%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  margin-left: 60px;
  z-index: 2;
}

.share-button {
  position: absolute;
  top: 20%;
  right: 5%;
  transform: translate(-50%, -50%);
  z-index: 11;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Add transition for background and transform */
} 

.share-button button {
  transition: width 2s ease; /* Add transition for width */
}

.share-button .nav-button {
  padding: 10px 20px;
  border: none;
}

.edit-button .nav-button {
border: none;
padding: 10px 20px;
}

.profile-details-container {
  display: flex;
  justify-content: center;
}

.public-profile .scrollable-content {
  overflow-y: scroll;
  height: 100vh;
}

.profile-details {
  padding: 0px;
  text-align: center; /* Center text in profile details */
  align-self: center;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
}

.columns {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; /* Space between banner and columns */
  width: 100%;
}

.column {
  flex: 1; /* Default for left and right columns */
  margin: 0 10px; /* Space between columns */
  text-align: center; /* Center text in each column */
}

.column.left-column {
  align-items: flex-end;
}

.column.left-column p{
padding: 0px;
}

.column.right-column {
  align-items: flex-start;
}

.column.center-column {
  align-items: center;
  padding: 0px 20px;
  margin-left: 0px;
  margin-right: 0px;
}

.column.left-column,
.column.right-column {
  flex: 1; /* Each of these columns takes equal space */
}

.column.center-column {
  flex: 1; /* Center column takes 2.5 times the space of the left/right columns */
}


.column h3 {
  margin-bottom: 10px; /* Space below headings */
}

.column p {
  margin: 5px 0; /* Space between paragraphs */
  color: #5E00D4;
}

.creator-page .question-container p {
  margin: 5px 0; /* Space between paragraphs */
  color: #5E00D4;
}

.profile-displayname {
  font-size: 24px;
  font-weight: bold;
  margin: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.profile-username {
  font-size: 16px;
  margin: 0px;
}

.profile-location {
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
}

.profile-location p{
  margin: 0px;
  margin-left: 5px;
  margin-right: 5px;
  min-width: fit-content;
}

.profile-location .location-drop-icon {
  width: 20px;
  height: 20px;
  margin-top: -2px;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.column p {
    margin: 0px;
}

.section-title {
  background-color: #5E00D4; /* Purple background */
  padding: 10px; /* Add some padding */
  border-radius: 8px; /* Optional: rounded corners */
  margin-bottom: 5px; /* Space below the title */
  width: 150px;
  text-align: center; /* Center text */
}
.section-title.featured-content {
    width: 100%;
    text-align: center; /* Center text */
    margin-bottom: 20px;
  }

.section-title h3 {
  margin: 0px;
  font-size: .8rem;
  border-radius: 5px;
}

.creator-page .question-container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-accounts .section-title {
  margin-bottom: 20px;
}

.genres .section-title {
  margin-bottom: 10px;
}

.creator-page .question-container.bio {
  margin-top: 30px;
  width: 80% !important;
}

.creator-page .question-container.bio p {
  text-align: center;
}


.creator-page .question-container.featured-content {
  width: 90%;
  gap: 10px;
}

.creator-page .question-container .genre-icons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  gap: 10px; /* Space between icons */
  width: 170px;
  align-items: center;
  justify-content: center;
}

.creator-page .question-container .genre-icons img {
  width: 35px;
  height: 35px;
  flex: 0 0 calc(33.33% - 10px); /* Each icon takes up 1/3 of the row minus the gap */
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.creator-page .question-container.genres {
  margin-bottom: 30px;
}

.ReactFlagsSelect-module_label__27pw9 {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #5E00D4;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  padding: 0px;
}
.ReactFlagsSelect-module_flagsSelect__2pfa2 {
  padding: 0px;
  height: 30px;
}

.ReactFlagsSelect-module_flagsSelect__2pfa2:hover {
    background-color: #EAD4FF;
}

.social-links a {
  color: #5E00D4;
  font-family: 'Inter', sans-serif;
  font-weight: 500; 
  font-size: 15px;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.social-links a:hover{
  color: #E608C6;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the spinner and text */
  align-self: center;
  margin-top: 200px;
}

.loading-spinner {
  border: 8px solid rgba(181, 46, 226, 0.2); /* Light border */
  border-top: 8px solid #E46FED; /* White top border */
  border-radius: 50%; /* Circular shape */
  width: 50px; /* Spinner width */
  height: 50px; /* Spinner height */
  animation: spin 1s linear infinite; /* Animation for spinning */
  margin-top: 10px; /* Space between text and spinner */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

  .creator-page .social-icon {
    width: 25px !important;
    height: 25px !important;
    margin-right: 5px;
  }

  .public-profile .social-icon {
    width: 25px !important;
    height: 25px !important;
    margin-right: 5px;
    padding: 0px;
  }

  .creator-page .youtube-icon {
    height: 30px !important;
  }

  .public-profile .youtube-icon {
    height: 30px !important;
    margin-top: -1px;
  }

  .social-accounts-list .official-links {
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    height: 150px;
    margin: 0px;
  }

  .official-links iframe{
    border-radius: 5px;
    overflow: hidden;
  }

  .official-links::-webkit-scrollbar {
    display: none;
  }

  .OFL-container {
    width: fit-content;
    height: calc(200px);
    background-color: white;
    border-radius: 5px;
    padding: 5px 0px;
    display: flex;
    align-items: end;
    justify-content: end;
    background-color: white;
  }

  .creator-page .social-icon {
    padding: 0px !important;
  }

  .profile-banner .overlay {
    background-color: rgba(94, 0, 212, 0.5);
  }

  .profile-displayname {
    min-width: 300px;
  }
  .profile-location {
    max-width: fit-content;
  }

  .question-container.featured-content iframe {
    width: 100%; /* Make iframes responsive */
  }
  
  .social-accounts-list {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between each social account */
  }
  
  .social-account-item {
    display: flex;
    justify-content: space-between; /* Space between platform name and link */
    align-items: center; /* Center items vertically */
  }
  
  .social-link-item {
    display: flex;
    justify-content: space-between; /* Space between platform name and link */
    align-items: center; /* Center items vertically */
    margin-bottom: 5px;
  }
  
  .public-profile .footer {
    background-color: #EAD4FF; /* Ensure the footer has a background color */
    color: #fff; /* Text color */
    padding: 10px 0; /* Add some padding */
    text-align: center; /* Center the text */
    margin-top: auto; /* Push footer to the bottom */
  }
  
  .share-selected {
    width: 125px; /* Adjust width as needed */
  }
   
  .share-selected:hover {
    background-color: #AE00FF;
    scale: none;
    z-index: 5;
  }
  
  .share-icons {
    display: flex;
    justify-content: space-around; /* Space icons evenly */
    align-items: center;
    gap: 15px;
    z-index: 5;
  }
  
  .share-icons img{
    width: 21px;
    height: 21px;
    z-index: 5;
  }

  .nav-button {
    transition: transform 0.3s ease; /* Smooth transition for scaling */
  }
  
  .nav-button p{
    margin: 0px;
  }
  
  .nav-button:hover p{
    color: #AE00FF;
  }
  
  .share-button {
  width: 175px;
  height: 40.35px;
  display: flex;
  justify-content: end;
  align-items: end;
  }
  
  .share-selected {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1.05); /* Slightly enlarge when selected */
    z-index: 11;
  }
   
  .share-selected:hover {
    background-color: #AE00FF;
    scale: none;
    z-index: 11;
  }
  
  .share-icons {
    display: flex;
    justify-content: space-around; /* Space icons evenly */
    align-items: center;
    gap: 15px;
    z-index: 11;
  }
  
  .share-icons img{
    width: 21px;
    height: 21px;
    z-index: 11;
  }

  .copy-message {
    position: absolute;
    top: 140%;
    left: 80%;
    transform: translate(-50%, -50%);
    z-index: 11;
    color: white;
    font-weight: 500;
    font-size: 13px;
    width: 180px;
    font-family: 'Inter', sans-serif;
  }

@media(max-width: 365px) {
  .section-title {
    width: 120px;
  }

  .profile-info {
    margin-top: 0px;
  }

  .profile-pic{
    width: 80px;
    height: 80px;
  }

  .creator-page .genre-icon {
    width: 35px !important;
    height: 35px !important;
  }

  .share-selected {
    width: 125px !important; /* Adjust width as needed */
  }

  .share-icons {
    display: flex;
    justify-content: space-around; /* Space icons evenly */
    align-items: center;
    gap: 8px !important;
    z-index: 5;
  }

  .share-icons img{
    width: 19px !important;
    height: 19px !important;
    z-index: 5;
  }
  
}

@media (max-width: 850px) {
  
  .question-container.bio {
    margin-top: 20px;
    margin-bottom: 5px;
    width: 75%;
  }
  .profile-details {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    width: 100%; /* Full width */
    align-items: center;
    padding: 0px 0px;
  }

  .section-title h3{
    font-size: .85rem;
  }

  .creator-page .profile-details .columns { 
    display: flex; /* Use flexbox for the columns */
    justify-content: space-between; /* Space between left and right columns */
    width: 95%; /* Full width for the columns */
    overflow-wrap: break-word;
    flex-wrap: wrap; /* Allow wrapping */
  }

  .profile-details .column.left-column {
    width: 50%; /* Adjust width to fit side by side */
    order: 1; /* Left column first */
    align-items: center;
    margin: 0px;
  }

  .profile-details .column.right-column {
    width: 50%; /* Adjust width to fit side by side */
    order: 2; /* Right column second */
    align-items: center;
    margin: 0px;
  }

  .profile-details .column.center-column {
    width: 100%; /* Full width for center column */
    order: 3; /* Center column last */
    position: relative;
    align-items: center;
    margin-top: 20px;
    max-width: 100%; /* Ensure it doesn't exceed the container */
  }

  .profile-banner .overlay {
    background-color: rgba(94, 0, 212, 0.5); /* Purple color with 50% opacity */
  }

  .edit-button {
    margin-left: 0px;
  }

  .edit-button .nav-button{
    transform: none;
    margin-left: 60px;
    margin-bottom: 10px;
    padding: 10px;
    min-width: 96.5px;  

  }

  .share-button {
    right: 3%;
    transform: none;
    top: 10%;
  }

  .share-selected {
    width: 130px; /* Adjust width as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1.05); /* Slightly enlarge when selected */
    z-index: 5;
  }
   
  .share-selected:hover {
    background-color: #AE00FF;
    scale: none;
    z-index: 5;
  }
  
  .share-icons {
    display: flex;
    justify-content: space-around; /* Space icons evenly */
    align-items: center;
    gap: 9px;
    z-index: 5;
  }

  .copy-message {
    position: absolute;
    top: 140%;
    left: 85%;
    font-size: 12px;
  }
}

@media (min-width: 850px) and (max-width: 1400px){
  .creator-page .question-container.bio {
    top: -75px; /* Position it based on the parent */
  }
}

@media (min-width: 850px){
.creator-page .profile-banner {
height: 300px !important;
min-height: 300px !important;
}

.creator-page .profile-banner.collapsed {
  height: 150px !important; /* Height when collapsed */
  min-height: 150px !important;
}

.profile-details-container {
  position: relative;
}

.profile-info {
  width: fit-content;
}

.creator-page .question-container.bio.cpage-bio {
  position: absolute; /* Change to absolute positioning */
  top: -75px;
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center horizontally */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  z-index: 10000;
  width: 70% !important;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.3); /* Background color */
  border-radius: 20px; /* Rounded corners */
  backdrop-filter: blur(5px); /* Blur effect */
  padding: 3px 10px; /* Padding */
}

.question-container.bio.desktop-bio {
  position: relative; /* Change to absolute positioning */
  text-align: center; 
  top: 0px;
  color: white;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.3); /* Background color */
  border-radius: 20px; /* Rounded corners */
  backdrop-filter: blur(5px); /* Blur effect */
  padding: 3px 10px; /* Padding */
}

.public-profile .profile-info {
  top: 50% !important;
}

.public-profile .profile-info {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.creator-page .question-container.bio p{
  color: white;
  font-weight: 500;
  z-index: 10001;
  font-size: 13px;
}
}