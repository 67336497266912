.settings-page {
    padding: 20px;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #C898FE;
    height: calc(90vh - 40px);
    overflow-y: scroll;
}

.settings-page-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 80%;    
    padding: 0px 100px;
    border: 2px solid rgb(230, 8, 198, 0.3);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 15px 10px;
}

.settings-page h1 {
    font-size: 40px;
    margin-bottom: 20px;
}

.settings-page p a {
    color: #3B006F;
    text-decoration: none;
    font-weight: 600;
}

.settings-page label {
    display: block;
    margin: 10px 0 5px;
}

.update-data-button {
    padding: 10px 15px;
    background-color: #3B006F;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.delete-account-button {
    background-color: #ffffff !important;
    color: #3B006F !important;
    margin-top: 100px !important;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    padding: 10px 15px;
}

.settings-page .delete-account-button:hover {
    background-color: #5E00D4 !important;
    color: #ffffff !important;
} 

.settings-page button:hover {
    background-color: #5E00D4;
} 

.settings-page input {
    background-color: rgba(255, 255, 255, 0.6);
    color: #ffffff;
}

.settings-page label {
    color: #3B006F;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.95;
}

.settings-page-input-container p{
    color: #3B006F;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.95;
}

.settings-page-container p {
    font-size: 17px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.95;
    color: #3B006F;
    margin: 0px
}

