.IconButton {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    margin: 0;
    outline: 0;
    border: 1px solid var(--color-gray-200);
    border-radius: 0.375rem;
    background-color: var(--color-gray-50);
    color: var(--color-gray-900);
    user-select: none;
  
    @media (hover: hover) {
      &:hover {
        background-color: var(--color-gray-100);
      }
    }
  
    &:active {
      background-color: var(--color-gray-100);
    }
  
    &[data-popup-open] {
      background-color: var(--color-gray-100);
    }
  
    &:focus-visible {
      outline: 2px solid var(--color-blue);
      outline-offset: -1px;
    }
  }
  
  .Icon {
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .Popup {
    position: absolute;
    box-sizing: border-box;
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    background-color: canvas;
    color: var(--color-gray-900);
    transform-origin: var(--transform-origin);
    transition: transform 150ms, opacity 150ms;
    z-index: 1000;
  
    &[data-starting-style],
    &[data-ending-style] {
      opacity: 0;
      transform: scale(0.9);
    }
  
    @media (prefers-color-scheme: light) {
      outline: 1px solid var(--color-gray-200);
      box-shadow:
        0px 10px 15px -3px var(--color-gray-200),
        0px 4px 6px -4px var(--color-gray-200);
    }
  
    @media (prefers-color-scheme: dark) {
      outline: 1px solid var(--color-gray-300);
      outline-offset: -1px;
    }
  }
  
  .Arrow {
    display: flex;
    position: absolute;
    z-index: 1001;
  
    &[data-side='top'] {
      bottom: -8px;
      rotate: 180deg;
    }
    &[data-side='bottom'] {
      top: -8px;
      rotate: 0deg;
    }
    &[data-side='left'] {
      right: -13px;
      rotate: 90deg;
    }
    &[data-side='right'] {
      left: -13px;
      rotate: -90deg;
    }
  }
  
  .ArrowFill {
    fill: canvas;
  }
  
  .ArrowOuterStroke {
    @media (prefers-color-scheme: light) {
      fill: var(--color-gray-200);
    }
  }
  
  .ArrowInnerStroke {
    @media (prefers-color-scheme: dark) {
      fill: var(--color-gray-300);
    }
  }
  
  .Title {
    margin: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
  
  .Description {
    margin: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--color-gray-600);
  }
  
  .Actions {
    display: flex;
    justify-content: end;
    gap: 1rem;
  }
  
  .Select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border: 1px solid var(--color-gray-200);
    border-radius: 0.375rem;
    background-color: var(--color-gray-50);
    cursor: pointer;
  }
  
  .SelectIcon {
    margin-left: 0.5rem;
  }
  
  .Positioner {
    position: absolute;
    z-index: 1000;
  }
  
  .Item {
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
  
  .ItemIndicator {
    margin-right: 0.5rem;
  }
  
  .ItemText {
    flex-grow: 1;
  }
  