.creator-typeform {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  height: 100vh; /* Full height of the viewport */
}

.creator-typeform h1 {
  margin-bottom: 5px; /* Space below the question */
  font-size: 2rem;
  letter-spacing: 0px;
  margin-top: 0px;
}

.creator-typeform input {
  background-color: #C898FE; /* Light background */
  border: 3px solid transparent; /* Set a transparent border */
  border-color: #C26DFF; /* Change border color on focus */
  border-radius: 12px; /* Ensure this is set */
  height: 35px; /* Adjust height */
  font-size: 16px;
  font-family: 'Montserrat-Bold', sans-serif;
  font-weight: 600; /* Use font-weight instead of font-style for bold */
  color: #fff;
  transition: border-color 0.3s ease, scale 0.3s ease; /* Smooth transition for border color */
  letter-spacing: 0.95px; /* Letter spacing for placeholder */
}

.creator-typeform .username-name-question input {
  width: 100vw; /* Adjust width as needed */
  max-width: 400px; /* Set a max width for larger screens */
  margin-bottom: 25vh; /* Space above and below the input */
  margin-top: 10px;
  z-index: 2;
}

.username-name-question input.empty {
  margin-bottom: 200px;
}

.typeform-title {
  font-size: 1.5rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold; /* Use font-weight instead of font-style for bold */
  color: #fff; /* Change to your desired color */
  letter-spacing: 0.95px;
}

.creator-typeform .form-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
}

.username-name-question {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    height: 100vh; /* Full height of the viewport */
}

.username-name-question h1 {
    margin-top: 20px; /* Space below the question */
    font-size: 2rem;
    z-index: 2;
    margin-bottom:10px;
}

.back-button {
  border: 3px solid #ffffff; /* Keep border color transparent */
  border-radius: 10px;
  box-sizing: border-box;
  color: white;
  cursor: pointer; /* Add cursor pointer for better UX */
  background-color: transparent; /* Background color */
  margin-top: 5px;
  padding: 7px 40px; /* Adjust padding as needed */
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
  width: 121px;
  display: flex; /* Use flexbox for alignment */
  justify-content: center; /* Center the text and icon */
  align-items: center; /* Align items vertically */
  margin-right: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  letter-spacing: 0.95px;
  z-index: 2;

}

.back-button img {
  margin-left: -10px; /* Push the icon further left */
  height: 15px; /* Keep the height as needed */
  z-index: 2;

}

.username-name-question .back-button {
    display: none; /* Hide the back button */
    margin-bottom: 50px;
}

.username-name-question .next-button {
  margin-left: 0px;
  z-index: 2;
}
.next-button {
  border: 3px solid #ffffff; /* Keep border color transparent */
  border-radius: 10px;
  box-sizing: border-box;
  color: white;
  cursor: pointer; /* Add cursor pointer for better UX */
  background-color: transparent; /* Background color */
  margin-top: 5px;
  padding: 7px 30px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
  width: 121px;
  margin-left: 10px;
  display: flex; /* Use flexbox for alignment */
  justify-content: space-between; /* Space between text and icon */
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  letter-spacing: 0.95px;
  z-index: 2;

}

.next-button:hover{
  background-color: #ffffff;
  color: #AB00C7
}
.back-button:hover{
  background-color: #ffffff;
  color: #AB00C7
}

.creator-typeform .icon{
  height: 15px;
  margin-left: 10px;
}

.creator-typeform .backIcon {
  margin-right: 12px; /* Match the spacing with the back button */
  height: 15px; /* Keep the height as needed */
}

.creator-typeform-buttons {
    display: flex;
    justify-content: space-between; /* Center the logo horizontally */
}
.logo-container {
    position: relative; /* Set the parent container to relative */
    width: 100%; /* Full width of the container */
    height: 40px; /* Set a height for the container */
    z-index: 2;
    margin-bottom: auto;
    width: 100%;
  }
.logo-and-logout{
  flex-grow: 1; /* Allow this container to take available space */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center the logo within this space */
  width: 100%; /* Full width to allow spacing */
  margin-top: 20px;
}

.creator-typeform input:focus {
  border-color: #DC07FF; /* Change border color on focus */
  outline: none; /* Remove default outline */
}
.username-name-question .input-container {
  display: flex; /* Use flexbox to align items */
  align-items: center; /* Center items vertically */
  flex-direction: column;
  width: 100%; /* Full width */
  max-width: 400px; /* Set a max width for larger screens */
}

.username-name-question input {
  flex: 1; /* Allow the input to take available space */
  background-color: #C898FE; /* Light background */
  background-repeat: no-repeat;
  background-position: right 10px center; /* Position the icon inside the input */
  background-size: 20px; /* Adjust size as needed */
  padding: 6px 6px 6px 20px !important;
  font-size: 16px !important;
  height: 45px !important;
  max-height: 45px !important;
  min-height: 30px !important;
}

.username-name-question input.unique {
  background-image: url('../assets/images/circle-confirm.svg'); /* Path to your confirm icon */
  padding: 6px 6px 6px 20px !important;
  font-size: 16px !important;
  height: 45px !important;
}

.username-name-question input.error{
  padding: 6px 6px 6px 20px !important;
  font-size: 16px !important;
  height: 45px !important;
}

.confirm-icon {
  height: 20px; /* Adjust size as needed */
} 

.genres-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 20px; /* Space between items */
  margin-top: 10px;
  margin-bottom: 10px;
}

.genre-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s;
  width: 120px;
  height: 120px;
  justify-content: center;
  align-items: center;
}

.genre-item:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.genre-icon {
  width: 60px; /* Adjust icon size */
  height: 60px; /* Adjust icon size */
}

.genre-label {
  margin-top: 10px; /* Space between icon and label */
  font-size: 15px; /* Font size for the label */
  color: #590AA0; /* Default color */
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
}

.selected-label {
  color: #fff; /* Color when selected */
}

.selected {
  background: #5a0aa02e; /* Highlight selected genres */
  border-radius: 10px; /* Rounded corners */
}

.question-description {
  font-size: 1rem; /* Adjust font size */
  color: #fff; /* Change text color */
  margin-top: 0px; /* Space above the paragraph */
  margin-bottom: 0px; /* Space below the paragraph */
  text-align: center; /* Center the text */
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

.social-question-description {
  font-size: 1rem; /* Adjust font size */
  color: #fff; /* Change text color */
  margin-top: 0px; /* Space above the paragraph */
  margin-bottom:30px; /* Space below the paragraph */
  text-align: center; /* Center the text */
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}
.socials-container {
  display: grid;
  grid-template-columns: repeat(3, 2fr); /* 4 columns */
  gap: 100px; /* Space between items */
  margin-top: 50px;
  margin-bottom: auto;
  width: fit-content;
}

.social-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  transition: transform 0.2s;
}

.social-item:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.social-item input{
  display: block;
  width: 150px;
  margin-top: 10px;
}

.social-item .input-container{
  position: absolute;
  margin-top: 145px;
}



.creator-typeform .social-icon {
  width: 100px; /* Adjust icon size */
  height: 100px; /* Adjust icon size */
  padding-top: 15px;
}
.creator-typeform .youtube-icon {
  width: 100px; /* Adjust icon size */
  height: 120px; /* Adjust icon size */
  margin-bottom: -10px;
}

.social-label {
  margin-top: 13px; /* Space between icon and label */
  font-size: 15px; /* Font size for the label */
  color: #590AA0; /* Default color */
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  display: none;
}

.selected {
  border-radius: 10px; /* Rounded corners */
  background: transparent;
}

.social-input::placeholder{
  color: #fff
}

.clicked-coordinates{
  display: none;
}

.region-select {
  background-color: #C898FE; /* Light background */
  border: 3px solid transparent; /* Set a transparent border */
  border-color: #C26DFF; /* Change border color on focus */
  border-radius: 12px; /* Ensure this is set */
  height: 35px; /* Adjust height */
  font-size: 16px;
  font-family: 'Montserrat-Bold', sans-serif;
  font-weight: 600; /* Use font-weight instead of font-style for bold */
  color: #fff;
  letter-spacing: 0.95px; /* Letter spacing for placeholder */
}
.world-map{
  height: 80%;
  width: 100%;
}

.region-container{
  margin-top: 0px;
  justify-items: center;
  align-items: center;
  text-align: center;
  height: 65% ;
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.logout-button-container{
  position: absolute; /* Position the logout button absolutely */
  right: 0; /* Align to the right */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust to center vertically */
  margin-top: 15px;
  margin-right: 5px;
}

.logout-button.nav-button{
  border: none;
  margin-left: auto;
  padding: 8px 20px;
}

.language-input-container {
  position: relative; /* Position relative for absolute positioning of suggestions */
  margin-bottom: auto;
  margin-top: 10px;
}
.language-input-container input{
  margin-bottom: 50px;
  margin-top: 20px;
}

.language-suggestions {
  position: absolute; /* Position suggestions absolutely */
  top: 50%; /* Position below the input */
  left: 0; /* Align to the left */
  right: 0; /* Align to the right */
  max-height: 150px; /* Set a max height for the suggestions box */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden;
  background-color: #C898FE; /* Background color for the suggestions box */
  border: 3px solid #C26DFF; /* Border color */
  border-radius: 8px; /* Rounded corners */
  z-index: 10; /* Ensure it appears above other elements */
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
}

.language-suggestions li {
  padding: 10px; /* Padding for each suggestion */
  cursor: pointer; /* Pointer cursor for better UX */
}

.language-suggestions li:hover {
  background-color: #C26DFF; /* Change background on hover */
  color: #fff; /* Change text color on hover */
}

.ReactFlagsSelect-module_label__27pw9{
  color: #fff;
  font-family: 'Montserrat-bold', sans-serif;
  font-weight: 400;
  font-size: 16px;
  border: none;
} 
.ReactFlagsSelect-module_selectBtn__19wW7{
  border: none;
  background-color: #C898FE;
  color: #fff;
  font-family: 'Montserrat-bold', sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.ReactFlagsSelect-module_selectBtn__19wW7:after{
  display: none;
  content: none !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  border: none !important; /* Remove the border */
  background: transparent !important; /* Make the background transparent */
  color: #fff !important; /* Set the text color */
  padding: 5px 10px !important; /* Adjust padding as needed */
}

.ReactFlagsSelect-module_selectBtn__19wW7:focus {
  outline: none !important; /* Remove the outline on focus */
}

.ReactFlagsSelect-module_selectValue__152eS {
  display: flex !important; /* Ensure the flag and label are displayed inline */
  align-items: center !important; /* Center items vertically */
}

.ReactFlagsSelect-module_label__27pw9 {
  margin-left: 5px !important; /* Add some space between the flag and the label */
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
  border: none !important; /* Remove border from dropdown */
  background: #C898FE !important; /* Set background color for dropdown */
}

.ReactFlagsSelect-module_selectOption__3pcgW {
  background: #C898FE !important; /* Set background color for options */
  color: #fff !important; /* Set text color for options */
}

.ReactFlagsSelect-module_selectOption__3pcgW:hover {
  background: #C26DFF !important; /* Change background on hover */
}

.ReactFlagsSelect-module_selectBtn__19wW7:hover{
  background: #C26DFF !important;
  color: #fff !important; 
}

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #C898FE; /* Background of the scrollbar track */
  border-radius: 10px; /* Rounded corners for the track */
}

::-webkit-scrollbar-thumb {
  background: #AB00C7; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #9B00B0; /* Darker color on hover */
}

.username-error-message{
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-align: center;
}

@media (max-width: 375px) {
  .typeform-logo{
    height: 25px !important;
  }

  .logout-button-container{
    margin-top: 15px !important;
    padding: 0px !important;
  }

  .creator-typeform .logout-button.nav-button{
    padding: 5px 10px !important;
    font-size: 12px !important;
  }

  .creator-typeform h1{
    font-size: 1.25rem !important;
    margin-bottom: 5px !important;
  }

  .genre-item{
    width: 100px !important;
  }

  .creator-typeform .genre-icon{
    width: 60px !important;
    height: 60px !important;
  }
}

@media (max-width: 810px) {
    .creator-typeform {
        height: 86vh; /* Set height for mobile */

    }
    .logo-container {
        max-height: 30px; /* Adjust height as needed */
    }
    .username-name-question input {
        font-size: 16px;
        padding: 6px; /* Padding inside the input */
        padding-left: 20px; /* Add left padding to align text */
    }
    .username-name-question input {
        margin-bottom: 10px;
        width: 70%;
        transition: margin-bottom 0.3s ease;
        z-index: 2;
    }
    .username-name-question .input-container{
      width: 60vw;
    }

    .creator-typeform h1{
        font-size: 1.4rem;
        margin-bottom: 10px;
        padding: 0px 10px;
    }
    .creator-typeform {
      text-align: center;
    }
    .username-name-question h1{
      font-size: 1.5rem;
      margin-bottom: 40px;
  }
    .next-button{
      padding-left: 35px;
      padding-bottom: 10px;
      padding-top: 10px;
      margin-bottom: 25px;
    }
    .back-button{
      margin-bottom: 25px;
    }
    .username-name-question::before {
      height: 100vh;
  }
  .username-name-question::before {
    content: ""; /* Required for pseudo-element */
    position: absolute; /* Position it absolutely */
    top: 0; /* Align to the top */
    left: 0; /* Align to the left */
    right: 0; /* Align to the right */
    bottom: 0; /* Align to the bottom */
    background-image: url('../assets/images/ninja-background.png'); /* Ninja background image */
    background-size: cover; /* Ensure the image covers the area */
    background-position: center; /* Center the image */
    opacity: 0.2; /* Set opacity to 20% */
    z-index: 1; /* Ensure it is behind other content */
}
.genres-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 20px 5px; /* Space between items */
  margin: 10px;
  padding: 10px 10px;
}
.socials-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  gap: 50px 50px; /* Space between items */
  margin-bottom: 70px !important; /* Space below the container */
  margin-top: 0px;
  position: relative; /* Set position relative for absolute positioning of inputs */
}

.region-container{
  margin-top: 0px !important;
  margin-bottom: 50px;
}

.creator-typeform .social-item .input-container{
  margin-top: 0px !important;
  width: fit-content !important;
  overflow: hidden;
}

.social-item input{
  width: 130px !important;
  min-width: 50px !important;

}

.social-item {
  position: relative; /* Set position relative for absolute positioning of inputs */
}
.username-name-question .input-container{
  position: relative;
  transform: none;
  top:auto;
  left: auto;

}

.creator-typeform .input-container {
  position: absolute; /* Position inputs absolutely */
  top: 100%; /* Position below the social item */
  left: 50%; /* Center the input */
  transform: translateX(-50%); /* Center the input */
  margin-top: 10px; /* Space between the social icon and the input */
  width: 160px; /* Set a width for the input */
}
.social-icon {
  width: 65px !important; /* Adjust icon size */
  height: 65px !important; /* Adjust icon size */
  padding: 0px 20px;
}
.youtube-icon {
  width: 100px; /* Adjust icon size */
  height: 100px; /* Adjust icon size */
  margin-top: -10px;
  margin-bottom: -12px;
}
.social-input{
  width: 120px;
  color: #fff;
  margin-top: 10px;
  padding: 2px 6px 2px 5px;
}
.social-question-description {
  margin-bottom: 20px !important;
}
.region-container{
  height: 65%;
  width: 90%;
}

.world-map{
  margin-top: -20px;
  margin-bottom: -30px;
}

.region-select{
  margin-top: -30px;
}

.logout-button-container{
  margin-top: 20px;
  padding: 5px;
  margin-right: 2px;
}

.question-five-container .bio-input{
  min-width: 250px !important;
  height: 200px !important;
  font-size: 16px !important;
}

.question-five-container{
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  width: 95% !important; /* Set width */
  margin-top: 20px !important; /* Center the container */
  margin-bottom: 70px !important;
}

.question-five-container .file-upload{
  margin-left: 0px !important;
  margin-bottom: 10px !important;
}

.question-five-container .upload-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.creator-typeform .question-five-container{
  overflow-y: scroll;
  height: 60vh;
}

.shareable-profile-title{
  font-size: 1.25rem !important;
}

.upload-label-container .upload-label{
  text-align: start;
  margin-left: 10px !important;
}

.link-inputs{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link-inputs .upload-label{
  margin-left: 0px !important;
}

.link-inputs .upload-label-sub{
  margin-left: 0px !important;
}

.link-input-container input{
  margin-left: 0px !important;
}

.add-link-icon{
  margin-left: 0px !important;
  width: 239px !important;
}

.creator-typeform .question-five-container{
  overflow-y: scroll; 
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: auto !important;
  margin-bottom: 5px !important;
}

.question-five-title{
  margin-top: 30px !important;
}

.question-four-container .language-input-container{
  margin-bottom: 200px !important;
}

}

@media (min-width: 850px) and (max-width: 1400px) {
  .username-name-question .input-container{
    width: 20vw;
  }
  .username-name-question input{
    width: 20vw;
    margin-bottom: 200px;
    margin-top: 40px;
  }

  .genres-container{
    gap: 10px 70px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .genre-icon{
    width: 60px;
    height: 60px;
  }
  .socials-container{
    margin-bottom: 65px; 
    padding-top: 0px;
    margin-top: 0px;
    gap: 50px 100px;
  }

  .social-question-description{
    margin-bottom: 10px !important;
    margin-top: 5px !important;
  }
  .social-icon{
    padding: 0px 42px;
    padding-bottom: 20px;
  }
  .social-input{
    width: 150px;
    padding: 6px 0px;
    padding-left: 20px; /* Add left padding to align text */
  }
.youtube-icon{
  padding-bottom: 15px;
}
.genre-question-description{
  margin-top: 5px !important;
}

.logout-button-container{
  margin-top: 15px;
  margin-right: 20px;
  padding: 5px;
}

.question-four-container .language-input-container{
  margin-bottom: 150px !important;
}

.creator-typeform .question-five-container{
  margin-bottom: 25px !important;
  margin-top: 20px !important;
}

.creator-typeform input{
  margin-top: 30px !important;
}

.socials-container input{
  margin-top: 50px !important;
}

.creator-typeform .question-five-container input{
  margin-top: 5px !important;
}
}

@media (min-width: 1401px) {
    .username-name-question input {
        margin-bottom: 37vh; /* Space above and below the input */
    }

    .username-name-question input.empty {
      margin-bottom: 37vh;
    }

    .username-name-question input.unique {
      margin-bottom: 37vh !important;
    }

    .username-name-question .username-error-message {
      margin-bottom: 33vh !important;
    }


    .username-name-question h1{
      margin-bottom: 50px !important;
    }
    .creator-typeform h1{
      margin-bottom: 20px;
    }

    .world-map{
      margin-top: 0px;
      margin-bottom: 100px;
    }

    .genre-icon{
      width: 100px;
      height: 100px;
    }
    .genres-container{
      gap: 40px 90px;
      margin-top: 30px;
      margin-bottom: 100px;
    }

    .question-description{
      margin-bottom: 10px;
      margin-top: 0px !important;
    }

    .socials-container{
      margin-bottom: 200px;
      gap: 100px 200px;
      margin-top: 0px;
    }
  
    .social-item .social-question-description{
      margin-bottom: 10px !important;
    }

    .social-item .input-container{
      position: absolute;
      margin-top: 225px;
    }

    .logout-button-container{
      margin-top: 15px;
      margin-right: 20px;
      padding: 5px;
    }

    .question-four-container .language-input-container{
      margin-bottom: 250px !important;
    }

    .question-five-container{
      margin-bottom: 100px !important;
    }
}

.creator-typeform-buttons.stacked-buttons {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: center; /* Center buttons horizontally */
  margin-top: 20px; /* Add some space above the buttons */
}

.bio-input {
  width: 70%;
  height: 50vh; /* Adjust height as needed */
  margin-bottom: 20px;
  border-radius: 12px;
  padding: 10px;
  border: 2px solid #EDDBFF;
  background-color: #B473FE;
  color: #fff;
  font-family: 'Montserrat-bold', sans-serif;
  font-weight: 400;
  align-self: center;
}
.bio-input::placeholder{
  color: #fff;
  font-family: 'Montserrat', sans-serif;
}

.bio-input:focus{
  border: 2px solid #DC07FF;
  outline: none;
} 

.file-upload label {
  color: #fff;
}

.banner-input{
  cursor: pointer;
  width: 20px;
}

.link-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between link inputs */
}

.link-inputs input {
  border-radius: 12px;
  border: 3px solid #C26DFF;
  background-color: #C898FE;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.question-five-container {
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space between the two columns */
  align-items: flex-start; /* Align items at the start */
  width: 70%;
  margin-top: auto;
  margin-bottom: auto;
}

.bio-container {
  display: flex;
  flex-direction: column;
  flex: 1; /* Allow the bio container to take available space */
  margin-right: 0px !important; /* Space between bio and upload section */
  align-items: center;
  justify-content: center;
}

.upload-container {
  flex: 1; /* Allow the upload container to take available space */
  width: fit-content;
}

.upload-container input{
  border: 2px solid #EDDBFF;
}

.file-upload {
  width: fit-content;
  align-items: start;
  justify-content: start;
}

.file-upload label {
  color: #fff;
  cursor: pointer;
  height: 55px;
}

.link-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between link inputs */
  padding-top: 40px;
}

.creator-typeform .link-inputs input{
  padding: 0px 0px 0px 20px;
  margin-left: 40px;
  margin-bottom: 5px;
  background-color: #B473FE;
}

.upload-icon {
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  cursor: pointer; /* Change cursor to pointer for better UX */
  margin-top: 5px;
}

.upload-label-container {
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
}

.upload-label {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  margin: 0px 0px 0px 10px;
  letter-spacing: 0.5px;
}

.link-inputs .upload-label {
  margin: 0px 0px 0px 40px;
}

.upload-label-sub {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  margin: 0px 0px 0px 40px;
}

.label-sub {
  margin: 2px 0px 0px 10px;
  font-size: 14px;
}

.add-link-icon{
  width: calc(20vw + 20px);
  margin-left: 40px;
  opacity: 0.8;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.add-link-icon:hover{
  opacity: 1;
  transform: scale(1.05), translateX(10px);
}

.remove-link-icon {
  color: #EDDBFF; /* Change color to match your theme */
  font-size: 40px;
  line-height: 20px;
  margin-left: 10px;
}

.creator-typeform .Transition-container{
  margin-bottom: 0px !important;
}