.chat-page {
    display: flex;
    justify-content: center;
    align-items: end;
    height: 100%;
    background-color: #EAD4FF;
    width: 100%;
}

.chat-page .chat-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    border: 1px solid #C26DFF;
    border-radius: 12px;
    overflow: hidden;
    background-color: #C898FE;
    margin-bottom: 50px;
    z-index: 1;
}

.messages {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    max-height: 60vh;
}

.message {
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;
    max-width: 80%;
}

.message.user {
    background-color: #5E00D4;
    color: white;
    align-self: flex-end;
    margin-left: auto;
    width: 40%;
    height: fit-content;
}

.message.ai {
    background-color: #fff;
    color: black;
    align-self: flex-start;
    margin-right: auto;
    width: 40%;
    height: fit-content;
}

.chat-page .input-container {
    display: flex;
    padding: 10px;
    background-color: #EAD4FF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-page input[type="text"] {
    flex: 1;
    padding: 10px;
    border: 1px solid #C26DFF;
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 0px;
}

.chat-page button {
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    background-color: #5E00D4;
    color: white;
    cursor: pointer;
    max-height: fit-content;
}

.chat-page button:hover {
    background-color: #4A00A0;
} 

.chat-header {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    width: 80%;
}

.chat-header h2 {
    width: 100%;
    text-align: start;
    font-size: 40px;
    margin: 10px;
    color: #4A00A0;
    font-style: italic;

}

.chat-header img {
    position: absolute;
    right: 15%;
    width: 250px;
    height: 250px;
    rotate: 15deg;
    z-index: 0;
}