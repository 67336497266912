.progress-bar-container {
  width: 20%;
  height: 10px;
  background-color: #e0e0e0; /* Background color of the progress bar */
  border-radius: 10px;
  border: 3px solid #A33CFE;
  margin-bottom: 20px;
  align-items: center;
  min-height: 10px;
}

.progress-bar {
  height: 100%;
  background-color: #5A0BA2; /* Color of the progress */
  border-radius: 5px;
  transition: width 0.3s ease; /* Smooth transition for the progress */
}


@media (max-width: 810px) {
    .progress-bar-container {
        margin-bottom: 15px;
        width: 60%;
        z-index: 2;
    }
}
