@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

html, body {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  height: 100vh; /* Ensure body takes full height */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  overflow-y: hidden; /* Prevent vertical scrolling */
  background-color: #9a45fe; /* Change this to your desired color */
}

.app {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  height: 100vh; /* Ensure the app takes at least the full height of the viewport */
  width: 100vw; /* Ensure the app takes full width */
  background: linear-gradient(330deg, #f318ff, #9a45fe, #50098e);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  -o-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@-moz-keyframes AnimationName {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@-o-keyframes AnimationName {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@keyframes AnimationName {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.menu-nav {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  background: linear-gradient(330deg, #f318ff, #9a45fe, #50098e);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  -o-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@-moz-keyframes AnimationName {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@-o-keyframes AnimationName {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@keyframes AnimationName {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}


h1{
  font-family: 'Montserrat', Helvetica;
  font-weight: 700;
  font-size: 2rem;
  color: #fff;
  letter-spacing: 0.5px;
  line-height: 28.5px;
  margin-bottom: 10px;
}

h2{
font-size: 1.5rem;
font-family: 'Montserrat', sans-serif;
font-weight: bold;
color: #fff;
letter-spacing: 0.5px;
text-align: center;
}

h3{
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: #fff;
  text-align: center;
}

p{
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #fff;
}

input{
background-color: #C898FE;
border: 3px solid transparent;
border-color: #C26DFF;
border-radius: 12px;
height: 30px;
width: 20vw;
padding: 6px 6px 6px 20px;
font-size: 16px;
font-family: 'Montserrat-Bold', sans-serif;
font-weight: bold;
color: #fff;
transition: border-color 0.3s ease;
margin-bottom: 15px;
letter-spacing: 0.95px;
min-width: 230px;
}

input::placeholder {
  color: #ededed; /* Placeholder text color */
  font-family: "Montserrat-Bold", Helvetica; /* Font family for placeholder */
  font-size: 16px; /* Font size for placeholder */
  font-weight: bold; /* Bold placeholder */
  letter-spacing: 0.95px; /* Letter spacing for placeholder */
}

input:focus {

  border-color: #DC07FF; /* Change border color on focus */
  outline: none; /* Remove default outline */
}


button{
  border: 3px solid #ffffff; /* Keep border color transparent */
  border-radius: 15px;
  box-sizing: border-box;
  color: white;
  max-width: 300px; /* Set a max width for larger screens */
  cursor: pointer; /* Add cursor pointer for better UX */
  background-color: transparent; /* Background color */
  transition: background-color 0.3s ease; /* Transition for hover effect */
  display: flex; /* Use flexbox for alignment */
  justify-content: space-between; /* Space between text and icon */
  padding: 0px; /* Add horizontal padding */
  margin-top: 0px;
  padding: 5px 10px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, scale 0.3s ease; /* Transition for hover effect */
}

/* Box shadow for enabled state */
button:hover{
  scale: 1.025; /* Add scale effect on hover */
  transition: transform 0.3s ease; /* Ensure smooth scaling on hover */
}

.creator-continue-button{
  min-width: 123px;
}

.fade-in {
  animation: fadeInUp 0.75s ease forwards; /* Animation for fading in */
}

.fade-button {
  animation: fadeInUp 2s ease forwards;
} 

@keyframes fadeInUp {
  from {
      opacity: 0; /* Start fully transparent */
      transform: translateY(20px); /* Start 20px below */
  }
  to {
      opacity: 1; /* End fully visible */
      transform: translateY(0); /* End at original position */
  }
}

.fade-enter {
  opacity: 0;
  transform: translateX(100%);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.15s ease, transform 0.3s ease;
}

.fade-exit {
  opacity: 1;
  transform: translateX(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.15s ease, transform 0.3s ease;
}

@media (max-width: 850px) {
  input{
    width: 50vw;
  }
}


