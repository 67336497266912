.circular-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.checkbox-input {
    display: none; /* Hide the default checkbox */
}

.checkbox-circle {
    width: 12px; /* Size of the circle */
    height: 12px; /* Size of the circle */
    border: 2px solid #718096; /* Border color */
    border-radius: 50%; /* Make it circular */
    position: relative;
    transition: background-color 0.3s;

}

.checkbox-circle.checked {
    background-color: #DB07C1; /* Background color when checked */
}

.manage-button {
    background-color: transparent;
    color: #DB07C1;
    font-size: 13px;
    font-weight: 600;
    font-family: Montserrat;
    align-items: center;
    justify-content: center;
    border: 1px solid #DB07C1;
    border-radius: 8px;



}