.studio-typeform {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    height: 100vh; /* Full height of the viewport */
  }

  .typeform-logo {
    height: 30px;
  }  
  
  .studio-typeform input, .event-milestone-form input {
    background-color: #C898FE; /* Light background */
    border: 3px solid transparent; /* Set a transparent border */
    border-color: #C26DFF; /* Change border color on focus */
    border-radius: 12px; /* Ensure this is set */
    height: 35px; /* Adjust height */
    font-size: 16px;
    font-family: 'Montserrat-Bold', sans-serif;
    font-weight: 600; /* Use font-weight instead of font-style for bold */
    color: #fff;
    transition: border-color 0.3s ease, scale 0.3s ease; /* Smooth transition for border color */
    letter-spacing: 0.95px; /* Letter spacing for placeholder */
  }
  
  .studio-typeform .username-name-question input {
    width: 100vw; /* Adjust width as needed */
    max-width: 400px; /* Set a max width for larger screens */
    margin-bottom: 25vh; /* Space above and below the input */
    margin-top: 10px;
    z-index: 2;
  }
  
  .typeform-title {
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold; /* Use font-weight instead of font-style for bold */
    color: #fff; /* Change to your desired color */
    letter-spacing: 0.95px;
  }
  
  .studio-typeform .form-container {
      display: flex;
      flex-direction: column;
      align-items: center; /* Center items horizontally */
      justify-content: center; /* Center items vertically */
  }
  
  .studio-typeform .username-name-question {
      display: flex;
      flex-direction: column;
      align-items: center; /* Center items horizontally */
      justify-content: center; /* Center items vertically */
      height: 100vh; /* Full height of the viewport */
  }
  
  .username-name-question h1 {
      margin-top: 20px; /* Space below the question */
      font-size: 2rem;
      z-index: 2;
      margin-bottom:10px;
  }
  
  .back-button {
    border: 3px solid #ffffff; /* Keep border color transparent */
    border-radius: 10px;
    box-sizing: border-box;
    color: white;
    cursor: pointer; /* Add cursor pointer for better UX */
    background-color: transparent; /* Background color */
    margin-top: 5px;
    padding: 7px 40px; /* Adjust padding as needed */
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
    width: 121px;
    display: flex; /* Use flexbox for alignment */
    justify-content: center; /* Center the text and icon */
    align-items: center; /* Align items vertically */
    margin-right: 10px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    letter-spacing: 0.95px;
    z-index: 2;
  
  }
  
  .back-button img {
    margin-left: -10px; /* Push the icon further left */
    height: 15px; /* Keep the height as needed */
    z-index: 2;
  
  }
  
  .username-name-question .back-button {
      display: none; /* Hide the back button */
      margin-bottom: 50px;
  }
  
  .username-name-question .next-button {
    margin-left: 0px;
    z-index: 2;
  }
  .next-button {
    border: 3px solid #ffffff; /* Keep border color transparent */
    border-radius: 10px;
    box-sizing: border-box;
    color: white;
    cursor: pointer; /* Add cursor pointer for better UX */
    background-color: transparent; /* Background color */
    margin-top: 5px;
    padding: 7px 30px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
    width: 121px;
    margin-left: 10px;
    display: flex; /* Use flexbox for alignment */
    justify-content: space-between; /* Space between text and icon */
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    letter-spacing: 0.95px;
    z-index: 2;
  
  }
  
  .next-button:hover{
    background-color: #ffffff;
    color: #AB00C7
  }
  .back-button:hover{
    background-color: #ffffff;
    color: #AB00C7
  }
  
  .studio-typeform .icon{
    height: 15px;
    margin-left: 10px;
  }
  
  .studio-typeform .backIcon {
    margin-right: 12px; /* Match the spacing with the back button */
    height: 15px; /* Keep the height as needed */
  }
  
  .studio-typeform-buttons {
      display: flex;
      justify-content: space-between; /* Center the logo horizontally */
  }
  .logo-container {
      position: relative; /* Set the parent container to relative */
      width: 100%; /* Full width of the container */
      height: 40px; /* Set a height for the container */
      z-index: 2;
      margin-bottom: auto;
      width: 100%;
    }
  .logo-and-logout{
    flex-grow: 1; /* Allow this container to take available space */
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center the logo within this space */
    width: 100%; /* Full width to allow spacing */
    margin-top: 20px;
  }
  
  .studio-typeform input:focus {
    border-color: #DC07FF; /* Change border color on focus */
    outline: none; /* Remove default outline */
  }
  .username-name-question .input-container {
    display: flex; /* Use flexbox to align items */
    align-items: center; /* Center items vertically */
    width: 100%; /* Full width */
    max-width: 400px; /* Set a max width for larger screens */
  }
  
  .username-name-question input {
    flex: 1; /* Allow the input to take available space */
    background-color: #C898FE; /* Light background */
    background-repeat: no-repeat;
    background-position: right 10px center; /* Position the icon inside the input */
    background-size: 20px; /* Adjust size as needed */
  }
  
  .username-name-question input.unique {
    background-image: url('../assets/images/circle-confirm.svg'); /* Path to your confirm icon */
    margin-bottom: 200px;
  }
  .confirm-icon {
    height: 20px; /* Adjust size as needed */
  }
  
  .genres-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 20px; /* Space between items */
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .genre-label {
    margin-top: 10px; /* Space between icon and label */
    font-size: 15px; /* Font size for the label */
    color: #590AA0; /* Default color */
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .selected-label {
    color: #fff; /* Color when selected */
  }
  
  .selected {
    background: #5a0aa02e; /* Highlight selected genres */
    border-radius: 10px; /* Rounded corners */
  }
  
  .question-description {
    font-size: 1rem; /* Adjust font size */
    color: #fff; /* Change text color */
    margin-top: 0px; /* Space above the paragraph */
    margin-bottom: 0px; /* Space below the paragraph */
    text-align: center; /* Center the text */
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
  }
  
  .social-question-description {
    font-size: 1rem; /* Adjust font size */
    color: #fff; /* Change text color */
    margin-top: 0px; /* Space above the paragraph */
    margin-bottom:30px; /* Space below the paragraph */
    text-align: center; /* Center the text */
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
  }
  
  .social-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    transition: transform 0.2s;
  }
  
  .social-item:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
  }
  
  .social-item input{
    display: block;
    width: 150px;
    margin-top: 10px;
  }
  
  .studio-typeform .social-icon {
    width: 100px; /* Adjust icon size */
    height: 100px; /* Adjust icon size */
    padding-top: 15px;
  }
  .studio-typeform .youtube-icon {
    width: 100px; /* Adjust icon size */
    height: 120px; /* Adjust icon size */
    margin-bottom: -10px;
  }
  
  .social-label {
    margin-top: 13px; /* Space between icon and label */
    font-size: 15px; /* Font size for the label */
    color: #590AA0; /* Default color */
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    display: none;
  }
  
  .selected {
    border-radius: 10px; /* Rounded corners */
    background: transparent;
  }
  
  .social-input::placeholder{
    color: #fff
  }
  
  .clicked-coordinates{
    display: none;
  }
  
  .region-select {
    background-color: #C898FE; /* Light background */
    border: 3px solid transparent; /* Set a transparent border */
    border-color: #C26DFF; /* Change border color on focus */
    border-radius: 12px; /* Ensure this is set */
    height: 35px; /* Adjust height */
    font-size: 16px;
    font-family: 'Montserrat-Bold', sans-serif;
    font-weight: 600; /* Use font-weight instead of font-style for bold */
    color: #fff;
    letter-spacing: 0.95px; /* Letter spacing for placeholder */
  }
  .world-map{
    height: 80%;
    width: 100%;
  }
  
  .region-container{
    justify-items: center;
    align-items: center;
    text-align: center;
    height: 65vh ;
    width: 65vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .game-titles-container{
    margin-top: 10px;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .game-title-inputs{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: auto;
  }

  .game-title-inputs input{
    width: 25vw;
  }

  .game-title-inputs .input{
    width: calc(25vw + 26px);
    padding: 0px 20px;
    height: 52.28px;
  }
  
  .logout-button.nav-button{
    border: none;
    margin-left: auto;
    padding: 8px 15px;
  }
  
  .platform-input-container {
    position: relative; /* Position relative for absolute positioning of suggestions */  }
  
  .platform-suggestions {
    position: absolute; /* Position suggestions absolutely */
    top: 60%; /* Position below the input */
    left: 0; /* Align to the left */
    right: 0; /* Align to the right */
    max-height: 150px; /* Set a max height for the suggestions box */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden;
    background-color: #C898FE; /* Background color for the suggestions box */
    border: 3px solid #C26DFF; /* Border color */
    border-radius: 8px; /* Rounded corners */
    z-index: 10; /* Ensure it appears above other elements */
    list-style-type: none; /* Remove bullet points */
    padding: 0; /* Remove default padding */
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
  }
  
  .language-suggestions li {
    padding: 10px; /* Padding for each suggestion */
    cursor: pointer; /* Pointer cursor for better UX */
  }
  
  .language-suggestions li:hover {
    background-color: #C26DFF; /* Change background on hover */
    color: #fff; /* Change text color on hover */
  }
  
  .ReactFlagsSelect-module_label__27pw9{
    color: #fff;
    font-family: 'Montserrat-bold', sans-serif;
    font-weight: 400;
    font-size: 16px;
    border: none;
  } 
  .ReactFlagsSelect-module_selectBtn__19wW7{
    border: none;
    background-color: #C898FE;
    color: #fff;
    font-family: 'Montserrat-bold', sans-serif;
    font-weight: 400;
    font-size: 16px;
  }
  
  .ReactFlagsSelect-module_selectBtn__19wW7:after{
    display: none;
    content: none !important;
  }
  
  .ReactFlagsSelect-module_selectBtn__19wW7 {
    border: none !important; /* Remove the border */
    background: transparent !important; /* Make the background transparent */
    color: #fff !important; /* Set the text color */
    padding: 5px 10px !important; /* Adjust padding as needed */
  }
  
  .ReactFlagsSelect-module_selectBtn__19wW7:focus {
    outline: none !important; /* Remove the outline on focus */
  }
  
  .ReactFlagsSelect-module_selectValue__152eS {
    display: flex !important; /* Ensure the flag and label are displayed inline */
    align-items: center !important; /* Center items vertically */
  }
  
  .ReactFlagsSelect-module_label__27pw9 {
    margin-left: 5px !important; /* Add some space between the flag and the label */
  }
  
  .ReactFlagsSelect-module_selectOptions__3LNBJ {
    border: none !important; /* Remove border from dropdown */
    background: #C898FE !important; /* Set background color for dropdown */
  }
  
  .ReactFlagsSelect-module_selectOption__3pcgW {
    background: #C898FE !important; /* Set background color for options */
    color: #fff !important; /* Set text color for options */
  }
  
  .ReactFlagsSelect-module_selectOption__3pcgW:hover {
    background: #C26DFF !important; /* Change background on hover */
  }
  
  /* For Webkit browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
  }
  
  ::-webkit-scrollbar-track {
    background: #C898FE; /* Background of the scrollbar track */
    border-radius: 10px; /* Rounded corners for the track */
  }
  
  ::-webkit-scrollbar-thumb {
    background: #AB00C7; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #9B00B0; /* Darker color on hover */
  }

.game-content-container{
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin-top: 30px;
}

.game-content-container .upload-label{
  margin-left: 0px;
}

.content-upload-container{
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.decision-container{
  display: flex;
  flex-direction: row;
  gap: 100px;
  margin-bottom: 25%;
  margin-top: 50px;
}

.decision-yes{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.decision-label{
  color: #590AA0;
}

.decision-yes:hover {
  color: #fff; /* Change the color of the text to white */
}

.decision-yes:hover .decision-label {
  color: #fff; /* Ensure the label changes to white on hover */
}

.decision-no:hover{
    color: #fff;
  }

  .decision-no:hover .decision-label {
    color: #fff; /* Ensure the label changes to white on hover */
  }

.decision-no{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.game-title-inputs .input{
  width: calc(25vw + 30px);
}

.studio-typeform.username-name-question input.error {
  margin-bottom: 0px;
}

.platform-input-container .platform-item {
  padding: 10px; /* Padding for each item */
  cursor: pointer; /* Pointer cursor for better UX */
  margin: 0px 20px;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #5E00D4;
}

.studio-typeform .platform-selection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.studio-typeform .platform-selection-container p{
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat-Bold', sans-serif;
  letter-spacing: 0.95px;
}

.studio-typeform .platform-icons {
  display: flex;
  flex-direction: row;
  width: calc(25vw + 30px);
  gap: 35px;
  flex: 1 1 calc(25% - 20px); /* Each icon takes up 1/4 of the row minus the gap */
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  transition: scale 0.3s ease;
}

.studio-typeform .platform-icon:hover{
  scale: 1.05;
}

.Transition-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: auto;
}

.username-name-question .Transition-container{
  margin-bottom: 0px !important; 
}

.news-upload {
  background-color: #C898FE;
  width: calc(25vw + 30px) !important;
  border: 2px solid #C26DFF;
  border-radius: 12px;
  height: calc(14vw + 17px);
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

.news-links-question{
  overflow-y: scroll !important;
}

@media (max-width: 375px) {

  .studio-typeform .typeform-title{
    font-size: 1.2rem;
  }

  .username-name-question h1 {
    margin-bottom: 20px !important;
    font-size: 1.25rem !important;
  }

  .studio-bio-question .typeform-title{
    font-size: 1.2rem !important;
  }

  .game-title-question .typeform-title{
    font-size: 1rem !important;
    margin-bottom: 0px !important;
  }

  .genre-question .typeform-title{
    font-size: 1.15rem !important;
  }

  .social-question .typeform-title{
    font-size: 1.15rem !important;
  }

  .game-bio-question .typeform-title{
    font-size: 1.15rem !important;
  }
}

@media (max-width: 810px) {
      .studio-typeform {
          height: 86vh; /* Set height for mobile */
  
      }
      .logo-container {
          max-height: 30px; /* Adjust height as needed */
      }
      .username-name-question input {
          margin-bottom: 200px;
          font-size: 16px;
          padding: 6px; /* Padding inside the input */
          padding-left: 20px; /* Add left padding to align text */
      }
      .username-name-question input {
          width: 70%;
          transition: margin-bottom 0.3s ease;
          z-index: 2;
      }
      .username-name-question .input-container{
        width: 60vw;
      }
      .studio-typeform {
        text-align: center;
      }
      .username-name-question h1{
        font-size: 1.5rem;
        margin-bottom: 40px;
    }
      .next-button{
        padding-left: 35px;
        padding-bottom: 10px;
        padding-top: 10px;
        margin-bottom: 25px;
      }
      .back-button{
        margin-bottom: 25px;
      }
      .username-name-question::before {
        height: 100vh;
    }
    .username-name-question::before {
      content: ""; /* Required for pseudo-element */
      position: absolute; /* Position it absolutely */
      top: 0; /* Align to the top */
      left: 0; /* Align to the left */
      right: 0; /* Align to the right */
      bottom: 0; /* Align to the bottom */
      background-image: url('../assets/images/ninja-background.png'); /* Ninja background image */
      background-size: cover; /* Ensure the image covers the area */
      background-position: center; /* Center the image */
      opacity: 0.2; /* Set opacity to 20% */
      z-index: 1; /* Ensure it is behind other content */
  }
  .genres-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 10px 5px; /* Space between items */
    margin: 10px;
    padding: 10px 10px;
  }
  
  .social-item {
    position: relative; /* Set position relative for absolute positioning of inputs */
  }
  .username-name-question .input-container{
    position: relative;
    transform: none;
    top:auto;
    left: auto;
  
  }

  .social-icon {
    width: 80px; /* Adjust icon size */
    height: 80px; /* Adjust icon size */
    padding: 0px 20px;
  }
  .youtube-icon {
    width: 100px; /* Adjust icon size */
    height: 100px; /* Adjust icon size */
    margin-top: -10px;
    margin-bottom: -12px;
  }
  .social-input{
    width: 120px;
    color: #fff;
    margin-top: 10px;
    padding: 2px 6px 2px 5px;
  }
  .social-question-description {
    margin-bottom: auto;
  }
  .studio-typeform .region-container{
    height: 45vh;
    width: 90vw;
    margin-bottom: 0px;
  }
  
  .world-map{
    margin-top: 0px;
    margin-bottom: -30px;
  }
  
  .region-select{
    margin-top: -30px;
  }

  .username-name-question .username-error-message{
    margin-bottom: 25vh;
    font-weight: 600;
    font-size: 16px;
    z-index: 2;
  }

  .question-two-container{
    overflow-y: scroll;
    margin-bottom: 10px !important;
    margin-top: 5px !important;
  }

  .studio-bio-question .typeform-title{
    margin-top: 40px !important;
    font-size: 1.3rem;
  }

  .game-title-inputs .input{
    width: 261.6px;
  }

  .game-title-question .typeform-title{
    font-size: 1.35rem;
  }

  .genre-question .typeform-title{
    font-size: 1.3rem;
    margin-top: 50px;
  }

  .social-question .typeform-title{
    font-size: 1.3rem;
    margin-top: 50px;
  }

  .game-bio-question .typeform-title{
    font-size: 1.3rem;
    margin-top: 50px;
  }

  .game-description-container{
    overflow-y: scroll;
    margin-bottom: 15px !important;
    margin-top: 5px !important;
    overflow-x: hidden;
  }

  .decision-icon{
    width: 100px;
    height: 100px;
  }

  .decision-container{
    gap: 30px;
    margin-bottom: 40% !important;
  }

  .decision-label{
    font-size: 1rem;
  }

  .username-name-question input.empty {
    margin-bottom: 200px;
  }

  .upload-label-sub {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #fff;
    margin: 0px;
  }

  .game-content-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .studio-bio-question{
    overflow-y: scroll;
  }

  .social-question{
    overflow-y: scroll !important;
  }

  .news-links-question .news-upload {
    width: 260px !important;
    height: 146px !important;
  }

  .news-links-question .game-title-inputs{
    margin-bottom: 20px !important;
  }

  .game-description-container{
    overflow-y: scroll;
    height: 50vh;
    margin: 10px 0px !important;
  }
}
   
  @media (min-width: 850px) and (max-width: 1400px) {
    .username-name-question .input-container{
      width: 20vw;
    }
    .username-name-question input{
      width: 5px;
      margin-top: 40px;
    }

    .username-name-question .username-error-message{
      margin-bottom: 175px;
    }
  
    .genres-container{
      gap: 10px 70px;
      margin-top: 10px;
      margin-bottom: 15px;
    }
    .genre-icon{
      width: 80px;
      height: 80px;
    }
    .social-icon{
      padding: 0px 42px;
      padding-bottom: 20px;
    }
    .social-input{
      width: 150px;
      padding: 6px 0px;
      padding-left: 20px; /* Add left padding to align text */
    }
  .youtube-icon{
    padding-bottom: 15px;
  }

  .game-description-container{
    margin: 10px 0px !important;
  }

  .decision-container{
    margin-bottom: 5%;
  }

  .decision-icon{
    width: 200px;
    height: 200px;
  }

  .studio-typeform .genre-question-description{
    margin-bottom: 0px !important;
  }
  .studio-typeform .socials-container{
    gap: 50px 80px;
  }
}

  @media (min-width: 850px) {
    .upload-label-sub{
      margin-left: 40px;
    }

    .game-content-container .upload-label{
      margin-left: 40px !important;
    }

    .question-two-container{
      margin-top: 40px !important;
    }

    .studio-typeform .typeform-title{
      font-size: 2rem;
    }

    .content-upload-container{
      margin-left: 30px;
    }

    .studio-typeform .socials-container{
      grid-template-columns: repeat(4, 1fr);
      margin-top: 10px;
    }

    .studio-typeform .upload-container .file-upload{
      margin-top: 25px;
    }

    .studio-typeform .content-upload-container{
      margin-top: 10px;
    }

    .studio-typeform .game-content-container{
      margin-top: 40px;
    }

  }
  
  @media (min-width: 1401px) {
    .studio-typeform .upload-label-sub{
      margin-top: 5px !important;
    }

    .studio-typeform .content-upload-container{
      margin-left: 30px;
    }
    
    .username-name-question .username-error-message{
        margin-bottom: 25vh;
      }
      .username-name-question h1{
        margin-bottom: 50px !important;
      }
      .studio-typeform h1{
        margin-bottom: 20px;
      }
  
      .world-map{
        margin-top: 0px;
        margin-bottom: 100px;
      }
  
      .genre-icon{
        width: 100px;
        height: 100px;
      }
      .genres-container{
        gap: 40px 90px;
        margin-top: 30px;
        margin-bottom: 100px;
      }
  
      .question-description{
        margin-bottom: 10px;
        margin-top: -10px;
      }

      .username-name-question input.error{
        margin-bottom: 5px !important;
      }

      .studio-typeform h1 {
        font-size: 2rem;
      }

      .studio-typeform .platform-icons{
        gap: 50px;
      }
  }
  
  .studio-typeform-buttons.stacked-buttons {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center buttons horizontally */
    margin-top: 20px; /* Add some space above the buttons */
  }
  
  .bio-input {
    width: 60%;
    height: 50vh; /* Adjust height as needed */
    margin-bottom: 20px;
    border-radius: 12px;
    padding: 10px;
    border: 2px solid #EDDBFF;
    background-color: #B473FE;
    color: #fff;
    font-family: 'Montserrat-bold', sans-serif;
    font-weight: 400;
    align-self: center;
  }
  .bio-input::placeholder{
    color: #fff;
    font-family: 'Montserrat', sans-serif;
  }
  
  .bio-input:focus{
    border: 2px solid #DC07FF;
    outline: none;
  } 
  
  .file-upload label {
    color: #fff;
  }
  
  .banner-input{
    cursor: pointer;
    width: 20px;
  }
  
  .link-inputs {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between link inputs */
  }
  
  .link-inputs input {
    border-radius: 12px;
    border: 3px solid #C26DFF;
    background-color: #C898FE;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
  }
  
  .question-five-container {
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Space between the two columns */
    align-items: flex-start; /* Align items at the start */
    width: 70%;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .bio-container {
    display: flex;
    flex-direction: column;
    flex: 1; /* Allow the bio container to take available space */
    margin-right: 20px; /* Space between bio and upload section */
    align-items: center;
    justify-content: center;
  }
  
  .upload-container {
    flex: 1; /* Allow the upload container to take available space */
    width: fit-content;
  }
  
  .upload-container input{
    border: 2px solid #EDDBFF;
  }
  
  .file-upload {
    width: fit-content;
    align-items: start;
    justify-content: start;
  }
  
  .file-upload label {
    color: #fff;
    cursor: pointer;
    height: 55px;
  }
  
  .link-inputs {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between link inputs */
    padding-top: 30px;
  }
  
  .link-inputs input{
    padding-left: 6px;
    margin-left: 40px;
    margin-bottom: 5px;
    background-color: #B473FE;
  }
  
  .upload-icon {
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
    cursor: pointer; /* Change cursor to pointer for better UX */
    margin-top: 5px;
  }
  
  .upload-label-container {
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 100%;
  }
  
  .upload-label {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #fff;
    margin: 0px 0px 0px 10px;
    letter-spacing: 0.5px;
  }
  
  .link-inputs .upload-label {
    margin: 0px 0px 0px 40px;
  }
  
  .upload-label-sub {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #fff;
  }
  
  .label-sub {
    margin: 2px 0px 0px 10px;
    font-size: 14px;
  }
  
  .add-link-icon{
    width: calc(20vw + 20px);
    margin-left: 40px;
    opacity: 0.8;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .add-link-icon:hover{
    opacity: 1;
    transform: scale(1.05), translateX(10px);
  }
  
  .remove-link-icon {
    color: #EDDBFF; /* Change color to match your theme */
    font-size: 40px;
    line-height: 20px;
    margin-left: 10px;
  }

  .game-description-container .add-link-icon{
    width: calc(20vw + 6px);
  }

  .news-upload label{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .news-links-question .add-link-icon{
    margin-left: 0px !important;
  }
