.payout-card-amount{
    font-size: 40px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    color: #3B006F;
    margin-bottom: 30px;

}

.payout-card-title{
    font-size: 16px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    color: #3B006F;
    margin-bottom: 15px;
}


.payout-card-button{
    font-size: 12px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    background-color: #A579D6;
    color: #FFFFFF;
    border-radius: 30px;
    padding: 7px 20px;
    border: none;
    cursor: pointer;
    width: 150px;
    align-items: center;
    justify-content: center;
}


.Payout-Card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}

.Payout-Card.resized{
    width: 30% !important;
}


.Payout-Card.not-resized{
    width: 30% !important;
}



