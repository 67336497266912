.footer {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    background-color: transparent; /* Background color for the footer */
    color: #fff; /* Text color */
    width: 100%; /* Full width */
    padding: 5px 0;
    margin-top: auto; /* Push footer to the bottom */
}

.header-app-route .footer {
    background-color: #EAD4FF;
}

.footer-logo {
    width: 26px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 0px; /* Space between logo and text */
}

.footer-text {
    font-family: 'Montserrat', medium; /* Use Montserrat font */
    font-size: 11px; /* Font size */
    text-align: center; /* Center text */
}

@media (min-width: 1200px) and (max-width: 1450px) {
    .footer {
        margin-top: auto;
        padding: 0;
    }

}
@media (min-width: 1450px) {
    .footer-logo {
        width: 30px;
    }
    .footer {
        padding-bottom: 10px;
    }
    .footer-text {
        font-size: 12px;
    }
}
@media (max-width: 821px) {
    .footer {
        padding-bottom: 0px;
        margin-bottom: 105px;
    }
}


