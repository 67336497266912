.creator-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.creator-profile .form{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 5px;
}

.input {
    background-color: #C898FE; /* Light background */
    border: 3px solid transparent; /* Set a transparent border */
    border-color: #C26DFF; /* Change border color on focus */
    border-radius: 12px; /* Ensure this is set */
    height: 35px; /* Adjust height */
    width: 20vw; /* Full width */
    padding: 6px; /* Padding inside the input */
    padding-left: 20px; /* Add left padding to align text */
    font-size: .75rem;
    font-family: 'Montserrat-Bold', sans-serif;
    font-weight: bold; /* Use font-weight instead of font-style for bold */
    color: #fff;
    transition: border-color 0.3s ease; /* Smooth transition for border color */
    margin-bottom: 15px; /* Space between inputs */
    letter-spacing: 0.95px; /* Letter spacing for placeholder */
}
.input::value {
    margin-left: 10px;
}

.input:focus {

    border-color: #DC07FF; /* Change border color on focus */
    outline: none; /* Remove default outline */
}

.input::placeholder {
    color: #ededed; /* Placeholder text color */
    font-family: "Montserrat-Bold", Helvetica; /* Font family for placeholder */
    font-size: .75rem; /* Font size for placeholder */
    font-weight: bold; /* Bold placeholder */
    letter-spacing: 0.95px; /* Letter spacing for placeholder */
    justify-content: center;
}

.creator-profile h2{


    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold; /* Use font-weight instead of font-style for bold */
    color: #fff; /* Change to your desired color */
    letter-spacing: 0.5px;
    margin-bottom: 0px;
    margin-top: 5px;
}

.creator-profile p{
    font-size: 15px;
    font-family: 'Montserrat-semiBold', sans-serif;
    color: #fff; /* Change to your desired color */
    margin-top: 5px;
    margin-bottom: 8px;
}

.creator-profile p a{
    color: #E46FED;
    text-decoration: none;
}

.creator-profile p a:hover{
    text-decoration: underline;
}

.creator-profile .socials-text{
    margin-top: 15px;
    margin-bottom: 10px;
}

.creator-profile .creator-continue-button {
    border: 3px solid #ffffff; /* Keep border color transparent */
    border-radius: 15px;
    box-sizing: border-box;
    color: white;
    max-width: 300px; /* Set a max width for larger screens */
    cursor: pointer; /* Add cursor pointer for better UX */
    background-color: transparent; /* Background color */
    transition: background-color 0.3s ease; /* Transition for hover effect */
    display: flex; /* Use flexbox for alignment */
    justify-content: space-between; /* Space between text and icon */
    padding: 0px; /* Add horizontal padding */
    margin-top: 10px;
    padding: 5px 10px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, scale 0.3s ease; /* Transition for hover effect */
}

/* Box shadow for enabled state */

.creator-profile .creator-continue-button:hover:not(:disabled) {
    scale: 1.025; /* Add scale effect on hover */
    transition: transform 0.3s ease; /* Ensure smooth scaling on hover */
}



.creator-profile .creator-continue-button .text-wrapper {
    color: #ededed;
    font-family: "Montserrat", Helvetica;

    font-size: 18px; /* Use rem for responsive font size */
    font-weight: 700;
    letter-spacing: 0.95px;
    line-height: 1.5;
    margin-left: 5px; /* Adjust line height for better spacing */
}

.creator-profile .creator-continue-button .icon {
    height: 20px; /* Adjust height as needed */
    width: auto; /* Maintain aspect ratio */

    color: #ededed; /* Set color for the icon */
    margin-left: 10px; /* Add some space between text and icon */
    margin-right: 2px;
    padding-top: 2px;
}

.social-buttons {
    display: flex;
    border-radius: 15px;   
    height:40px;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.social-google {
    background-color: #fff;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    height: 45px;
    width: 47px;
    border: 1px solid #CBD5E0;
}
.social-google:hover{
    background-color: #FFB9FD;
}

.social-x {

    background-color: #fff;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    height: 45px;
    width: 47px;
    border: 1px solid #CBD5E0;
}

.social-x:hover{
    background-color: #FFB9FD;
}

.social-google img{

    height: 25px;
    width: 25px;
}

.social-x img{
    height: 25px;
    width: 25px;
    margin-top: 1px;
}

.header{
    width: 95vw;
    padding-left: 20px;
    padding-right: 20px;
}

.creator-profile p{
    text-align: center;
}   

@media (max-width: 821px) {
    .input {
        width: 65vw;
    }
    .creator-profile h2{
        margin-top: 25px;
    }
    .creator-profile .creator-continue-button{
        padding: 15px 0px;

        padding-left: 10px;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        height: 6vh;
        width: 32vw;
        margin-top: 10px
    }
    .creator-profile .creator-continue-button .icon {
        margin-left: 8px;
        margin-right: 10px;

        margin-bottom: 2px;
        padding: 0px;
    }
    .creator-profile .creator-continue-button .text-wrapper{
        font-size: 15px;
    }
    .creator-profile .form{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 15px;
    }
    .input {
        background-color: #C898FE; /* Light background */
        border: 3px solid transparent; /* Set a transparent border */
        border-color: #C26DFF; /* Change border color on focus */
        border-radius: 12px; /* Ensure this is set */
        height: 35px; /* Adjust height */
        padding-left: 10px;
        padding: 6px 6px 6px 10px; /* Padding inside the input */
        font-size: 16px;
        font-family: 'Montserrat-Bold', sans-serif;
        font-weight: bold; /* Use font-weight instead of font-style for bold */
        color: #fff;
        transition: border-color 0.3s ease; /* Smooth transition for border color */
        margin-bottom: 20px; /* Space between inputs */
    }
    .creator-profile p{
        font-size: 15px;
        font-family: 'Montserrat-semiBold', sans-serif;
        color: #fff; /* Change to your desired color */
        margin-top: 6px;
        margin-bottom: 8px;
    }
    .creator-profile{
        height: 100.5%;
    }

    .studio-profile{
        height: 100.5%;
    }

    .app {
        overflow-y: scroll;
        overflow-x: hidden;
    }
    ::-webkit-scrollbar {
        display: none;
    }
}
@media (min-width: 1440px) {
    .input {
        width: 20vw;
        margin-bottom: 25px;
        height: 45px;

    }
    .creator-profile .creator-continue-button{
        margin-top: 20px;
    }
    .creator-profile .form{ 
        margin-top: 40px;
    }
    .creator-profile h2{
        font-size: 1.75rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold; /* Use font-weight instead of font-style for bold */
        color: #fff; /* Change to your desired color */
        letter-spacing: 0.5px;
        margin-bottom: 0px;
        margin-top: 40px;
    }
}

@media (min-width: 900px) and (max-width: 1540px) {
    .input {
        width: 20vw;
        margin-bottom: 20px;
        height: 30px;
    }
    .creator-profile h2{
        margin-top: 0px;
    }
    .creator-profile .creator-continue-button{
        margin-top: 0px;

        margin-bottom: 0px;
    }
    .creator-profile .form{
        margin-top: 15px;
        margin-bottom: 0px;
    }
}

.password-container {
  position: relative; /* Position relative for absolute positioning of icons */
}

.divider-vector {
  position: absolute; /* Position absolute to align with input */
  right: 40px; /* Adjust position */
  top: 35%; /* Center vertically */
  transform: translateY(-50%); /* Center vertically */
  height:35px;
}

.eye-vector {
  position: absolute; /* Position absolute to align with input */
  right: 12px; /* Adjust position */
  top: 35%; /* Center vertically */
  transform: translateY(-50%); /* Center vertically */
  cursor: pointer; /* Change cursor to pointer for better UX */
  width: 20px;
}
