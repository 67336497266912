.edit-creator-page.header-app-route{
    background-color: #EAD4FF;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh
}

.edit-buttons-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    overflow: scroll;
    padding-bottom: 100px;
}

.edit-buttons{
    background-color: #EAD4FF;
    color: black;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
}

.edit-creator-page button{
    background-color: white;
    color: black;
    width: 80%;
    padding: 2px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.update-button-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-creator-page .update-button{
    background-color: #5E00D4;
    color: white;
    margin-top: 0px;
    height: 54px;
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 2px solid rgb(117, 117, 117);
}


.edit-buttons img{
    width: 20px;
}

.edit-buttons-icon{
    padding: 10px;
    background-color: #5E00D4;
    border-radius: 10px;
    width:24px;
    height:24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-buttons .continue-icon{
    width: 20px;
    rotate: 270deg;
    margin-right: 10px;
}

.edit-creator-page .profile-banner{
    position: relative;
    height: 200px;
    width: 100%;
}

.profile-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    z-index: 2;
    transition: opacity 0.3s ease;
}

.edit-creator-page .input{
    margin-bottom: 0px;
    background-color: rgba(255, 255, 255, 0.648);
    color: #5E00D4;
    border: 3px solid #5E00D4;
}

.edit-creator-page .input::placeholder{
    color: #5E00D4;
}

.edit-creator-page h2{
    margin-top: 10px;
    margin-bottom: 10px;
}

.content-input-container input{
    height: 30px;
}

.social-links-container input{
    height: 30px;
}

.edit-buttons input{
    height: 30px;
}


@media (max-width: 850px) {
    .edit-buttons-container{
        padding-bottom: 200px;
    }
}

.social-links-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 300px;
}

.social-link {
  display: flex;
  align-items: center; /* Align items vertically */
}

.edit-creator-page .social-icon {
  width: 35px !important; /* Adjust icon size */
  height: 35px !important; /* Adjust icon size */
  margin-right: 10px; /* Space between icon and input */
  padding: 0px;
}

.edit-creator-page .youtube-icon{
    width: 35px !important;
    height: 40px !important;
    margin-right: 10px;
    margin-bottom: -5px;
}

.language-list {
  max-height: 200px; /* Set a maximum height for the list */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 3px solid #5E00D4; /* Add a border */
  border-radius: 12px; /* Rounded corners */
  background-color: #fff; /* Background color */
  margin-top: 0px; /* Space above the list */
  width: 20vw;
  background-color: rgba(255, 255, 255, 0.648);
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 5px 0px;
}

.language-list .language-item {
  padding: 10px; /* Padding for each item */
  cursor: pointer; /* Pointer cursor for better UX */
  margin: 0px 5px;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #5E00D4;
}

.language-item:hover {
  background-color: #f0f0f0; /* Change background on hover */
}

.language-item.selected {
  background-color: #5E00D4; /* Highlight selected items */
  color: white; /* Change text color for selected items */
}

.ReactFlagsSelect-module_flagsSelect__2pfa2:hover {
    background-color: none;
}