.studio-page .profile-pic {
    border-radius: 0%;
    border: none;
}

.studio-page .studio-resource-icon {
    width: 40px !important;
    height: 40px !important;
}

.studio-page .game-card{
    width: 30vw;
    height: 20vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;

}

.studio-page .game-card img{
    filter: blur(5px);
    transition: filter 0.3s ease;
    transition: transform 0.5s ease;
}


.studio-page .game-card h2{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -15%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    text-shadow: 1px 1px 1px #000;
}

.studio-page .game-card:hover img{
    filter: blur(1px);
    transition: filter 0.3s ease;
    cursor: pointer;
}

@media (max-width: 850px) {
    .studio-page .section-title{
        width: 80vw !important;
    }

    .studio-page .game-card{
        width: 80vw !important;
        height: 45vw !important;
    }
}