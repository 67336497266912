.offer-content-container{
    transition: all 0.3s ease-in-out;
}

.offer-button-container-inner.resized{
    transition: all 0.3s ease-in-out;
    gap: 10px;
}

.offer-button-container-inner.not-resized{
    transition: all 0.3s ease-in-out;
    gap: 40px;
}


.game-square.not-resized img{


    width: 295px;
    height: 295px;
    transition: all 0.3s ease-in-out;
}



.game-square.resized img{
    width: 310px;
    height: 310px;
    transition: all 0.3s ease-in-out;
}


.accordion-item{
    width: 100% !important;
    border-radius: 13.33px;
    border: none !important;
    background-color: #FFFFFF;
    border-bottom: 1px solid rgba(59, 0, 111, 0.6) !important;
}


.accordion-header{
    width: 100% !important;
}


.offer-content-container button.accordion-button:hover{
    background-color: #FFFFFF !important;
    color: #3B006F !important;
    transform: none !important;
}




button.accordion-button{
    width: 100% !important;
    max-width: 100% !important;
    min-width: 90% !important;
    color:#3B006F !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    font-family: "Montserrat" !important;
    letter-spacing: 0.02em !important;
    padding: 15px 15px !important;
}

button.accordion-button:hover{
    font-weight: 600 !important;
    transform: none !important;
    scale: none !important;
}


button.accordion-button:focus{
    box-shadow: none !important;
    outline: none !important;
    z-index: 0 !important;
}

.accordion-button:not(.collapsed) {
    background-color: #FFFFFF !important;
    box-shadow: none !important;
}

.accordion-button.collapsed::after {
    background-image: url("../../assets/function-icons/plus-icon.svg") !important;
    transform: rotate(0deg) !important;
}

.accordion-button::after {
    background-image: url("../../assets/function-icons/plus-icon.svg") !important;
    transform: rotate(-45deg) !important;
}






.accordion{
    width: 100% !important;
    padding: 5px 10px !important;
}








@media (min-width: 1700px) {

    .game-square.not-resized img{
        width: 295px;
        height: 295px;
    }


    .game-square.resized img{
        width: 295px;
        height: 295px;
    }



    .offer-button-container-inner.resized{
        gap: 30px;
    }


    
    
    .offer-button-container-inner.not-resized{
        gap: 40px;
    }
}