.view-campaign-button {
    position: relative;
    top: -20%;
    left: 0%;   
    border: none; 
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

.view-campaign-button:hover {
    backdrop-filter: none;
}


.offer-content-container {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

}

.game-square img{
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.campaign-explorer {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

