.signup-selection{
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center items horizontally */
    text-align: center;
    height: 100vh; /* Full height of the viewport */
}

.signup-selection h1{
    font-size: 2.5rem;
    padding-top: 0vh;
    font-family: 'Montserrat', sans-serif;
    font-style: bold;
    color: #fff; /* Change to your desired color */
    letter-spacing: 0.5px;
    line-height: 28.5px;
    white-space: nowrap; /* Prevent wrapping */
}

.signup-selection p {
    font-family: 'Montserrat', sans-serif;
    color: #fff; /* Change to your desired color */
}

.button-container {
    display: flex; /* Use flexbox for buttons */
    justify-content: center; /* Center buttons horizontally */
    margin-bottom: 20px; /* Space below the buttons */
    position: relative; /* Ensure the container is positioned for absolute children */
    perspective: 1000px; /* Add perspective to the container */
}

.button-container button {
    background: #6C1BBC;
    border: 3px solid #C273FE;
    border-radius: 50px;
    cursor: pointer;
    color: #E2D1F2;
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5vw;
    font-weight: 700;
    letter-spacing: 0.95px;
    margin: 3vw 3vw 3vw 3vw;
    width: 22.3vw;
    height: 30.5vh;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease; /* Add transition for box-shadow */
    position: relative; /* Position relative for the button */
    overflow: hidden; /* Hide overflow for the light ray effect */
    z-index: 1; /* Ensure button text is above the light ray */
    transition: transform 0.3s ease; /* Smooth transition for transform */
}

/* Add glow effect on hover */ 
.button-container button:hover {
    box-shadow: 0 0 20px rgba(234, 106, 255, 0.8), 0 0 40px rgba(234, 106, 255, 0.6); /* Glow effect */
}

.signup-selection p a {
    position: relative; /* Position relative for the pseudo-element */
    text-decoration: none; /* Remove default underline */
    color: #E46FED; /* Set the text color */
}

.signup-selection p a:hover {
    text-decoration: underline; /* Add underline on hover */
}

.button-container button.selected {
    background: #A837FE;    
    box-shadow: 0 0 20px rgba(234, 106, 255, 0.8), 0 0 40px rgba(234, 106, 255, 0.6); /* Glow effect */
    color: #ffffff;
}

.continue-path-button {
    border: 3px solid #ffffff; /* Keep border color transparent */
    border-radius: 18px;
    box-sizing: border-box;
    color: white;
    height: 7vh; /* Use viewport height for responsive sizing */
    width: fit-content; /* Use percentage for width */
    max-width: 300px; /* Set a max width for larger screens */
    cursor: pointer; /* Add cursor pointer for better UX */
    background-color: transparent; /* Background color */
    transition: background-color 0.3s ease; /* Transition for hover effect */
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Space between text and icon */
    padding: 0 20px; /* Add horizontal padding */
    transition: background-color 0.3s ease, box-shadow 0.3s ease, scale 0.3s ease; /* Transition for hover effect */
}

/* Box shadow for enabled state */

.continue-path-button:hover:not(:disabled) {
    scale: 1.025; /* Add scale effect on hover */
    transition: transform 0.3s ease; /* Ensure smooth scaling on hover */
}

.continue-path-button:disabled {
    background-color: transparent; /* Keep the same background color when disabled */
    cursor: not-allowed; /* Change cursor to indicate disabled state */
    opacity: 0.6; /* Optional: make the button slightly transparent */
    transition: opacity 0.3s ease, transform 0.3s ease, scale 0.3s ease; /* Add transition for opacity and transform */
}

.continue-path-button .text-wrapper {
    color: #ededed;
    font-family: "Montserrat", Helvetica;
    font-size: 18px; /* Use rem for responsive font size */
    font-weight: 700;
    letter-spacing: 0.95px;
    line-height: 1.5;
    margin-left: 5px; /* Adjust line height for better spacing */
}

.continue-path-button .icon {
    height: 20px; /* Adjust height as needed */
    width: auto; /* Maintain aspect ratio */
    color: #ededed; /* Set color for the icon */
    margin-left: 15px; /* Add some space between text and icon */
    margin-right: 2px;
}

.button-container p{
    color: #E2D1F2;
}

/* Media Query for Mobile Devices (390px - 810px) */
@media (max-width: 810px) {
    .button-container button {
        height: 20vh; /* Set button height for mobile */
        width: 40vw;
        margin: 15vw 3vw 20vw 3vw;
        font-size: 5.5vw;
        border-radius: 25px;
    }
    .signup-selection h1{
        font-size: 2rem;
        padding-top: 3vh;
    }
    .continue-path-button{
        height: 7vh;
        border-radius: 15px;
        margin-bottom: 0px;
    }
    .continue-path-button .icon{
        height: 18px;
    }
    .continue-path-button .text-wrapper {
        font-size: 15px;
    }

    .signup-selection {
        height: 80vh;
    }

    .app {
        overflow-y: scroll;
        overflow-x: hidden;
    }

    ::-webkit-scrollbar {
        display: none;
    }
}

@media (max-width: 390px) {
    .signup-selection h1{
        font-size: 1.75rem;
    }
}

@media (max-width: 333px) {
    .signup-selection h1{
        font-size: 1.5rem;
    }
}

/* Media Query for Desktop Devices (1200px - 1410px) */
@media (min-width: 900px) and (max-width: 1410px) {
    .button-container button {
        height: 30vh; /* Set button height for desktop */
        width: 19vw;
        margin: 2vw 3vw 3vw 3vw;
        border-radius: 40px;
    }
    .signup-selection h1{
        font-size: 2rem;
        padding-top: 0vh;
    }
    .continue-path-button{
        padding-top: 10px;
        padding-bottom: 10px;
        height: 8.5vh;
        border-radius: 15px;
    }
    .continue-path-button .icon{
        height: 20px;
        margin-left: 10px;
        margin-right: 0px;
    }
    .continue-path-button .text-wrapper {
        margin-left: 0px;
    }
}

.loading-overlay {
    transition: opacity 0.3s ease; /* Add t ransition for opacity */
    opacity: 1; /* Fully visible when loading */
    width: 100vw;
}

.loading-overlay.hidden {
    opacity: 0; /* Fade out when not loading */
}

.loading-video{
    width: 100%;
    height: 100%;
}

.button-container .studio-button:hover::before {
    transform: translate(-50%, -50%) scale(0); /* Scale down on hover */
}

.button-container .creator-button:hover::before {
    transform: translate(-50%, -50%) scale(0); /* Scale down on hover */
    transition: transform 0.5s ease; /* Smooth transition */
}

.button-container.studio-selected::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 80%;
    width: 200%; /* Adjust size as needed */
    height: 300%; /* Adjust size as needed */
    background: radial-gradient(circle, rgba(234, 106, 255, 0.5) 0%, rgba(234, 106, 255, 0) 70%);
    transform: translate(-50%, -50%) scale(1); /* Scale to full size when selected */
    transition: transform 0.5s ease; /* Smooth transition */
    z-index: 0; /* Behind the buttons */
}

.button-container.creator-selected::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 30%;
    width: 200%; /* Adjust size as needed */
    height: 300%; /* Adjust size as needed */
    background: radial-gradient(circle, rgba(234, 106, 255, 0.5) 0%, rgba(234, 106, 255, 0) 70%);
    transform: translate(-50%, -50%) scale(1); /* Scale to full size when selected */
    transition: transform 0.5s ease; /* Smooth transition */
    z-index: 0; /* Behind the buttons */
}

@media (min-width: 2100px) {
    .button-container button {
        font-size: 55px;
    }
}