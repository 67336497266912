.header {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Space between logo, nav links, and nav button */
  padding-top: 25px;
  padding-bottom: 20px;
  max-height: 40px;
  position: relative; /* Set position to relative for absolute positioning of dropdown */
  color: #fff;
  font-family: "Montserrat", Helvetica;
}

.banner {
  max-width: 20%; /* Set max width of the banner */
  width: fit-content; 
}

.header-logo {
  height: auto;
  max-height: 30px; /* Set max height to 112% of the navigation height */
}

.navigation {
  display: flex; /* Ensure navigation links are in a row */
  justify-content: center;
  align-items: center; /* Center the nav links vertically */
  height: 40; /* Ensure this height is consistent */
}

.nav-link {
  margin: 2px;
  margin-left:10px;
  margin-right:20px;
  text-decoration: none;
  color: #fff; /* Ensure the text color is visible */
  font-style: medium;
  font-size: 16px;
  font-family: "Inter", Helvetica;
}

.nav-button {
  background-color: #AE00FF; /* Adjust button color */
  color: white;
  border-radius: 30px;
  width: fit-content;
  height: fit-content;
  padding: 10.5px 32px;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effect */
}

.nav-button:hover {
  background-color: white; /* Change background to white on hover */
  color: #AB00C7; /* Change text color on hover *//* Optional: add border color on hover */
}
.nav-button {
  color: white;
  font-family: "Inter", Helvetica;
  font-style: medium;
  font-size: 15px;
}

/* Dropdown styles */
.dropdown {
  position: relative; /* Ensure dropdown is positioned correctly */
  display: none; /* Hide dropdown by default */
}

.dropdown-content {
  display: none; /* Hide dropdown content by default */
  position: absolute; /* Position it below the dropdown */
  background-color: white; /* Background color */
  min-width: 160px; /* Minimum width */
  z-index: 1; /* Ensure it appears above other elements */
}

.dropdown.open .dropdown-content {
  display: block; /* Show dropdown content when open */
}

/* Media query for mobile devices */
@media (max-width: 395px) {
    .header {
        display: flex;
        justify-content: center; /* Center the logo */
        padding: 10px; /* Adjust padding for mobile */
    }

    .banner {
        cursor: pointer; /* Change cursor to pointer for the logo */
    }

    .header-logo {
        width: auto; /* Allow logo to maintain its aspect ratio */
        height: 40px; /* Set a fixed height for the logo */
    }

    .dropdown-content {
        display: none; /* Hide dropdown content by default */
        position: absolute; /* Position it below the logo */
        background-color: #161616; /* Match the header background */
        width: 100%; /* Full width */
        z-index: 1; /* Ensure it appears above other elements */
        transform: scale(1.3); /* Start with zero size */
        opacity: 0; /* Start with zero opacity */
        transition: transform 0.3s, opacity 0.3s; /* Smooth transition */
    }

    .dropdown.open .dropdown-content {
        display: block; /* Show dropdown content when open */
    }
}

/* Optional: Additional media query for larger screens */
@media (min-width: 1200px) {
    .header {
        display: flex;
        padding-left: 50px;
        padding-right: 50px;
      }
      
      .banner {
        margin-right: 32px; /* Gap between banner and navigation */
      }
      
      .header-logo {
        margin-right: 20px; /* Optional: Add space between logo and navigation */
      }
      
      .nav-button {
        background-color: #AE00FF; /* Optional: Add space between navigation and header-right */
      }
}

.nav-link {
  color: #fff;
  &:hover {
    text-underline-offset: 5px;
    text-decoration: underline;
  }
}

.header-right {
  margin-left: auto; /* Push the nav button to the right */
  margin-right: 10px;
}

.header-app-container {
  display: flex;
}

.side-nav.right {
  position: fixed; /* Keep it fixed to the right */
  top: 0;
  right: 0; /* Position it on the right side */
  width: 250px; /* Width of the side nav */
  height: 100%; /* Full height */
  background-color: white; /* Background color */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5); /* Shadow for depth */
  transition: transform 0.3s ease; /* Smooth transition */
  transform: translateX(100%); /* Initially hidden */
  z-index: 1000; /* Ensure it appears above other content */
}

.side-nav.open {
  transform: translateX(0); /* Show the side nav */
}

.main-content {
  flex: 1; /* Take the remaining space */
  transition: margin-right 0.3s ease; /* Smooth transition for margin */
  transition: margin-left 0.3s ease;
}

.main-content.no-blur {
  filter: none; /* Remove blur effect */
}

/* Add this to your Header.css or a relevant CSS file */
.blur {
  filter: blur(5px); /* Adjust the blur amount as needed */
  transition: filter 0.3s ease; /* Smooth transition for the blur effect */
}

.highlight-on-hover {
  transition: background 0.3s ease; /* Smooth transition for scaling */
}

.hover-line {
  background-color: transparent; /* Start with transparent background */
}

.highlight-on-hover:hover {
background: linear-gradient(to right, #3B006F, #e608c55a);
}

.highlight-on-left-hover {
  transition: background 0.3s ease; /* Smooth transition for scaling */
}

.hover-line {
  background-color: transparent; /* Start with transparent background */
}

.highlight-on-left-hover:hover {
background: linear-gradient(to left, #3B006F, #e608c55a);
}

.highlight-on-left-hover:hover .hover-line {
  background-color: #E608C6; /* Change to the desired color on hover */
}

.highlight-on-left-hover.dashboard .hover-line {
  background-color: #E608C6; /* Change to the desired color on hover */
}

.highlight-on-left-hover.dashboard {
  background: linear-gradient(to left, #3B006F, #e608c55a);
  transition: background 0.3s ease;
}

.side-nav.left {
  position: fixed; /* Keep it fixed to the left */
  top: 0;
  left: 0; /* Position it on the left side */
  background-color: #3B006F; /* Background color */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5); /* Shadow for depth */
  transition: transform 0.3s ease; /* Smooth transition */
  transform: translateX(-100%); /* Initially hidden */
  z-index: 1000; /* Ensure it appears above other content */
}

.side-nav.left.open {
  transform: translateX(0); /* Show the left side nav */
}

.menu-logo-container {
  transition: opacity 0.3s ease; /* Smooth transition for opacity */
}

.fade-out {
  opacity: 0; /* Fully transparent */
}

.fade-in-header {
  opacity: 1; /* Fully opaque */
}

.no-header-blur {
    filter: none; /* Remove blur effect */
}
