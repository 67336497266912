.modalOverlay {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: start;
    align-items: start;
    opacity: 1;
    pointer-events: none; /* Prevent interaction when not visible */
    transform: translateX(-150%);
    transition: transform 0.5s ease;
    width: calc(60% - 225px);
}


.modalOverlay.visible {
    opacity: 1;
    pointer-events: auto; /* Allow interaction when visible */
    transform: translateX(0);
    transition: transform 0.5s ease;
}

.modalContent {
    background-color: #FAF4FF;
    padding: 20px;
    border-radius: 8px;
    height: calc(100%);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modalOverlay.visible.not-started.modal-pushed {
    width: calc(60% - 225px);
    transform: translateX(225px); /* Slide in to the original position */
    transition: transform 0.3s ease, width 0.3s ease;
}

.modalOverlay.visible.not-started.modal-not-pushed {
    width: 50%;
    transform: translateX(0); /* Slide in to the original position */
}

.modalOverlay.visible.started.modal-pushed {
    transform: translateX(225px); /* Slide in to the original position */
    transition: transform 0.3s ease, width 0.3s ease;
    width: calc(100% - 225px);
}

.modalOverlay.visible.started.modal-not-pushed {
    width: 100%;
    transform: translateX(0); /* Slide in to the original position */
    transition: transform 0.3s ease, width 0.3s ease;
}


.closeButton { 
    position: absolute;
    left: 0px;
    top: 0px;
    background: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 30px;
    border: 2px solid #615F63;
    color: #615F63;
    padding: 3px 10px;
    margin: 20px;
}