.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: visible; /* Always visible */
  opacity: 1; /* Always fully visible */
}

.modal-content {
  background: linear-gradient(to right, #AB00C7, #590AA0);
  padding: 30px 55px;
  border-radius: 15px;
  text-align: center;
  margin-top: 0px;
  max-width: 200px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content h2 {
  margin-top: 0px;
}

.modal-content p {
  margin: 10px 0 15px 0px; /* Default margin */
  font-family: 'Inter', sans-serif;
  font-size: 16px;
}

.modal-content p.error-message {
  margin: 5px 10px 20px 10px; /* Increased margin for specific error message */
}

.modal-button {
      border: 3px solid #ffffff; /* Keep border color transparent */
      border-radius: 15px;
      box-sizing: border-box;
      color: white;
      max-width: 300px; /* Set a max width for larger screens */
      cursor: pointer; /* Add cursor pointer for better UX */
      background-color: transparent; /* Background color */
      transition: background-color 0.3s ease; /* Transition for hover effect */
      padding: 5px 10px;
      font-size: 15px;
      margin-top: 10px;
}
    
    /* Box shadow for enabled state */

.modal-button:hover{
     background-color: #ffffff; /* Add scale effect on hover */
     color: #AB00C7;
     transition: transform 0.3s ease; /* Ensure smooth scaling on hover */
}

@media (min-width: 1450px) {
    .modal-content {
        padding: 40px 70px 40px 70px;
        margin-top: 0px;
    }
}

@media (max-width: 821px) {
    .modal-content {
        padding: 30px 55px 30px 55px;
        margin-top: 0px;
    }
}
