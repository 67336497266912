.game-page .profile-pic {
    border-radius: 0%;
    border: none;
}

.game-page .profile-banner {
    height: 200px !important;
    min-height: 200px !important;
}

.game-page .question-container.bio p {
    color: #5E00D4;
    width: 40vw;
    margin-bottom: 20px;
}

.game-page .carousel-container {
    width: 50vw;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
}

.news-section-container{
    margin-bottom: 50px;
    margin-top: 25px;
}

.game-page .news-card {
    width: 40vw;
    height: 22vw;
    border-radius:10px;
    overflow: hidden;
    margin-top: 15px;
}

.game-page .news-card img{
    border-radius: 10px;
    overflow: hidden;
    width: 40vw;
    max-height: 34vw;
    cursor: pointer;
}

.game-page .carousel-container .swiper-slide img{
    border-radius: 10px;
    overflow: hidden;
    height: 28vw;
}

.game-page .carousel-container .pagination-preview img{
    border-radius: 5px;
    overflow: hidden;
}

.swiper-button-next, .swiper-button-prev {
    color: #C898FE !important;
}

.game-page .columns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 50%;
    gap: 50px;
}

.game-page .swiper-pagination {
    position: relative;
    margin-top: 5px;
}

.custom-pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

.pagination-preview img{
    cursor: pointer;
    width: 100px; 
    height: fit-content;
}

.gamepage-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.gamepage-section .section-title{
    display: flex;
    align-items: center;
    justify-content: center;
}


.gamepage-section h3{
    width: fit-content;
}

.gamepage-section .genre-icons {
    width: 100% !important;
    height: auto;
    gap: 10px !important;
    margin-bottom: 5px;
    margin-top: 5px;
}

.gamepage-section .genre-icon {
    height: 50px !important;  
    width: 50px !important;
}

.gamepage-section .platform-icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
}

.gamepage-section .platform-icon {
    height: 50px !important;  
    width: 50px !important;
}

.platform-icons .platform-icon{
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(33.33% - 10px);
    box-sizing: border-box;
}

.gamepage-section .social-links .social-icon {
    height: 40px !important;
    width: 40px !important;
}

.gamepage-section .social-links a {
    font-size: 18px;
    text-decoration: underline;
    text-underline-offset: 4px;
    margin-left: 10px;
}

.game-page .gamepage-section{
    gap:10px;
}

.gamepage-section .genre-icons{
    margin-top: 0px !important;
}

.carousel-container{
    margin-bottom: 20px !important;
}

@media (max-width: 850px) {
    .game-page{
        height: 86vh;
    }

    .game-page .section-title{
        padding-left: 5px;
        padding-right: 5px;
    }
    
    .news-section-container{
        margin-bottom: 75px;
    }

    .game-page .columns{
        gap: 20px;
    }

    .gamepage-section .section-title h3{
        font-size: 14px !important;
    }


    .game-page .question-container.bio p {
        color: #5E00D4;
        width: 80vw;
        font-size: 14px;
    }

    .game-page .carousel-container {
        width: 80vw;
        border-radius: 10px;
        overflow: hidden;
    }

    .game-page .columns {
        width: 100vw;
    }

    .game-page .profile-details {
        width: 100vw;
    }

    .game-page .profile-details .columns {
        display: flex; /* Use flexbox for the columns */
        justify-content: space-between; /* Space between left and right columns */
        width: 92%; /* Full width for the columns */
        overflow-wrap: normal;
        flex-wrap:nowrap /* Allow wrapping */
    }

    .gamepage-section .platform-icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        gap: 20px !important;
        margin-bottom: 5px;
        margin-top: 10px;

    }

    .gamepage-section .platform-icon {
        height: 40px !important;  
        width: 40px !important;
    }
    
    .gamepage-section .social-links .social-icon {
        height: 30px !important;
        width: 30px !important;
    }

    .gamepage-section .genre-icon {
        height: 40px !important;  
        width: 40px !important;
    }

    .gamepage-section .genre-icons {
        width: 100%;
        height: auto;
        gap: 10px !important;
        margin-bottom: 0px;
        margin-top: 5px;
    }

    .gamepage-section .social-links a {
        font-size: 16px;
        text-decoration: underline;
        text-underline-offset: 4px;
        margin-left: 0px;
    }

    .gamepage-section .social-accounts-list {
        margin-top: 10px;
    }

    .game-page .news-card {
        width: 80vw;
        height: 44vw;
        border-radius:10px;
        overflow: hidden;
        margin-top: 15px;
    }
    
    .game-page .news-card img{
        border-radius: 10px;
        overflow: hidden;
        width: 80vw;
        height: 100%;
        cursor: pointer;
    }

    .news-section-container .section-title{
        width: 80vw !important;
    }

    .development-stage-section-container p{
        font-size: 16px !important;
        margin-top: 0px !important;
    }
}