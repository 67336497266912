.event-milestone-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    height: 100% !important;
    width: 100%;
}

.event-milestone-form.started {
    width: 100%;
}


.event-milestone-form h1 {
    font-size: 30px;
    font-weight: 500;
    margin: 20px 0px;
    color: black;
}

.event-milestone-form h3 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
}

.event-milestone-form img {
    width: 80px;
    height: 80px;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: linear-gradient(145deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}

.event-milestone-form img::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(145deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    pointer-events: none;
}


.event-milestone-form .input {
    border-radius: 30px;
    width: 300px;
    max-width: 300px;
    height: 40px;
    max-height: 40px;
    background-color: transparent;
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.95px;
    text-align: center;
    padding-left: 0px;


}

.event-milestone-form .input::placeholder {
    color: rgb(0, 0, 0, .5);
    font-size: 16px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

.event-milestone-form .next-button {
    border: 3px solid rgb(97, 95, 99);
    border-radius: 30px;
    box-sizing: border-box;
    color: rgb(97, 95, 99);
    margin-top: auto !important;
    margin-bottom: 50px;
    margin-left: 0px !important;
    text-align: center;
    justify-content: center;
    padding: 5px 5px !important;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    width: 100px;
    height:40px;
    max-height: 40px;
}

.event-milestone-form .next-button:hover {
    background-color: transparent;
    border: 3px solid #C26DFF;
}

.event-milestone-form .error {
    margin-top: 0px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: rgb(0, 0, 0, .5);
}

.vertical-line {
    border-left: 3px solid #3B006F; /* Set the color and width of the line */
    height: 100%; /* Set the desired height of the line */
    margin: 0 20px; /* Optional: Add margin to position the line */
}

.event-milestone-form .left-form {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #bdbdbd;
    border-radius: 10px;
}

.expandable-button-container {
    position: relative;
    display: inline-block;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #bdbdbd;
    border-radius: 10px;
    cursor: pointer;
}

.expandable-button-container:hover {
    box-shadow: 0 0 10px rgba(157, 16, 136, 0.349);
    border: 1px solid #C26DFF;
    cursor: pointer;
}

.expandable-button-container:hover .expandable-button {
    background-color: #C26DFF;
}

.expandable-button {
    background-color: #9C9B9D;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 22px 22px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: absolute;
    bottom: -22px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
}

.expandable-button:hover {
    background-color: #C26DFF;
}

.description {
    visibility: hidden;
    opacity: 0;
    width: 200px; /* Adjust width as needed */
    background-color: #f9f9f9;
    color: #333;
    text-align: center;
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    top: 100%; /* Position below the button */
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.expandable-button-container:hover .description {
    visibility: visible;
    opacity: 1;
}

.event-milestone-form .continue-icon {
    position: absolute;
    bottom: -17px;
    left: 50%;
    transform: translateX(-50%);
    height: 35px;
    width: 35px;
    background-color: transparent;
}